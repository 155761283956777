<template>
  <a-spin :spinning="confirmLoading">
    <div class="box">
      <h3 class="title">签字人申请</h3>
      <div class="content">
        <van-cell title="申请部门" :value="model.applyCompanyName"></van-cell>
        <van-cell title="申请人" :value="model.applyUserName"></van-cell>
        <van-cell title="签字人姓名" :value="model.signUserName"></van-cell>
        <van-cell v-if="!model.endTime&&!model.startTime" title="项目名称" :value="model.projectName"></van-cell>
        <van-cell v-if="model.startTime" title="授权开始时间" :value="model.startTime"></van-cell>
        <van-cell v-if="model.endTime" title="授权结束时间" :value="model.endTime"></van-cell>
        <van-cell v-if="!model.endTime&&!model.startTime" title="单项目授权" >单项目授权</van-cell>

        <div class="butBox" v-if="taskId">
          <van-button :disabled="butdisabled" size="small" type="primary" @click="ConsentSignedApplication(true)">通 过</van-button>
    
          <van-button :disabled="butdisabled" size="small" style=" margin-left: 15px;" type="default" @click="ConsentSignedApplication(false)">拒 绝</van-button>
        </div>
       
        <!-- <van-cell>
            <template #title>
              <span class="custom-title">文件路径</span>
            </template>
            <template #default>
              <a style="display:block;margin:5px 0;"
                :href="model.signminiourl" rel="noopener noreferrer">
                下载预览
              </a>
            </template>
        </van-cell> -->
      </div>
      
    </div>
   
  </a-spin>
</template>

<script>
import {
  getSignedApplication,
  ConsentSignedApplication
} from '@/api/api'
import {getTodoList} from '@/api/activitiApi'
import { 
  Cell,
  Button,
  Dialog 
} from 'vant';

export default {
    //签字人申请审核页面
    name:'SignedApplication',
    components:{
      [Cell.name]:Cell,
      [Button.name]:Button,
      
    },
    data() {
        return {
          confirmLoading:false,
          model:{},
          instanceId:'',
          taskId:'',
          butdisabled:false
        }
    },
    mounted() {
    },
    methods:{
      async getSignedApplication(val){
        this.confirmLoading = true;
        try {
          const datas= await getSignedApplication({id:val})
          if(datas.success){
            this.model=datas.result
            // this.jdDataList=this.model.phase.split(',')
            // this.getDict()
            
            this.confirmLoading = false;
          }
        } catch (error) {
          this.$message.error(error)
          this.confirmLoading = false;
        }
      },
      getTodoList(val){
        let obj ={
          businessKey:val,
          "pageNum": 1,
          "pageSize": 10,
        }
        getTodoList(obj).then(res=>{
          console.log(res,'getTodoList');
          if(res.result.list.length>0){
          this.instanceId= res.result.list[0].instanceId
          this.taskId= res.result.list[0].taskId
          }else{
            // this.$message.error('获取instanceId&taskId失败!')
            this.taskId = ''
          }
        })
      },
      ConsentSignedApplication(flag){
        let obj={
          "instanceId": this.instanceId || "",
          "taskId": this.taskId || "",
          "variables": {
            comment:flag? "同意":"不同意",
            pass: flag
          }
        }
        Dialog.confirm({
          title: `${flag?'同意申请':'拒绝申请'}`,
          message:`${flag?'确认同意这条申请吗?':'确认拒绝这条申请吗?'}`
        })
        .then(() => {
          ConsentSignedApplication(obj).then(res=>{
                if(res.success){
                  this.$message.success(res.message)
                  this.butdisabled=true
                }else {
                  this.$message.error(res.message)
                  
                }
              })
        })
          .catch(() => {
            // on cancel
          });
        
      }
    
    },
    computed:{
        reQuery() {
          return this.$route.query
        },
        // contract_id() {
        //   return this.$route.query.id || this.$route.query.businessKey||"1528563317854220290";
        // },
        business_key(){
          return this.$route.query.businessKey;
        },
        token(){
          return this.$route.query.token
        }
    },
    watch:{
      'business_key':{
          handler(val) {
            if(val) {
              this.getSignedApplication(val);
              this.getTodoList(val)
            }
          },
          immediate:true
      }
    },
}
</script>

<style lang="less" scoped>
.title{text-align: center;padding: 5px 0;background-color:#002b45 ;color: #fff;}
.content {height: calc(100vh - 95px);overflow-y: auto;}
.butBox{
  margin-top: 10px;
  padding-right: 15px;
  display: flex;
  justify-content: flex-end;
}
</style>
