<template>
    <a-spin tip="Loading..." :spinning="spinning" style="overflow:auto">
        <pdfSeal  v-if="visible"></pdfSeal>
        <footer class="foot">Copyright © 2021 首辅工程设计有限公司 蜀ICP备2021009126号</footer>
    </a-spin>
</template>

<script>
import pdfSeal from './pdfSeal.vue'
import {
	getPdfTotal
} from '@/api/api';
export default {
    name:'PDF盖章页面',
    components:{pdfSeal},
    data() {
        return {
            spinning:false,
            visible:true,
            // totalObj:{
            //     total:0,
            //     tmpFile:''
            // }
            
        }
    },
    computed:{
        contractId() {
            return this.$route.query.id
        },
        contractFileUrl() {
            return this.$route.query.fileUrl
        },
    },
    created() {
        // this.getTotalPage();
    },
    methods:{
        // //获取图纸总页数
        // getTotalPage() {
        //     this.spinning = true;
        //     // return
        //     getPdfTotal({fileUrl:this.contractFileUrl||'https://doc.shoufusheji.com/img/temp/1652926026080.pdf'}).then(res => {
        //         if(res.success) {
        //             this.visible = true;
        //             this.totalObj.tmpFile = res.result.tmpFile;
        //             this.totalObj.total = res.result.count ? res.result.count : 0;
        //         }else {
        //             this.$notification.error({
        //                 message: '错误提示',
        //                 description: res.message,
        //                 duration: 0,
        //             });
        //             this.visible = false;
        //         }
        //     }).finally(() => {
        //         this.spinning = false;
        //     })
        // },
    }
}
</script>

<style scoped lang="less">
/deep/.ant-spin{top:100px !important;}
.foot {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-size: 12px;
    background-color: #f6f6f6;
    border-top: 1px solid #ebebeb;
}

</style>