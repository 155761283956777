<template>
    <a-spin tip="Loading..." :spinning="spinning" class="seal">
        <header class="seal_heard">
            <span class="title">{{title}}盖章</span>
        </header>
        <div class="file_name">
            <a-tabs v-model="activeKey" size="small" @change="tabChange">
                <a-tab-pane 
                    v-for="(name,index) in fileLists" 
                    :key="index">
                    <span slot="tab" :title="name">{{name.slice(0,6)+'...'}}</span>
                </a-tab-pane>
            </a-tabs>
        </div>
        <div class="main">
            <div class="left">
                <p v-show="overLoad" style="color:red;font-size:16px">请先加载完所有页码,再进行拖章</p>
                <j-form-container :disabled="disableSubmit||overLoad">
                    <div class="sign">
                        <h4>印章大全</h4>
                        <div class="sign_opt">
                            <p class="sign_opt_item" v-for="(item,index) in chapters" :key="index" >
                                <span>{{item.sealname}} <i v-if="item.category==1" style="color:red">（可盖骑缝章）</i> </span>
                                <el-tooltip  content="拖拽到目标位置" placement="top" :hide-after="3000"  >
                                    <!-- <img :src="item.attachment"  :draggable="true" :title="item.sealname" :style="{'max-width':item.width + 'px','max-height':item.height+ 'px'}" v-on:dragstart="selectDrag($event,item)" v-on:dragend="selectDragEnd($event,item)" /> -->
                                    <div :draggable="true" style="display: inline-block;align-self: center;"
                                      @dragstart="selectDrag($event, item)" @dragend="selectDragEnd($event, item)"
                                      :style="[{ 'width': item.width + 'px' }, { 'height': item.height + 'px' }, { 'background-image': `url(${item.attachment})` }, { 'background-size': ` ${item.width}px ${item.height}px` }, { 'background-position': ` ${item.width}px ${item.height}px` }]">
                                    </div>
                                </el-tooltip>
                            </p>
                        </div>
                    </div>
                </j-form-container>
            </div>
            <div class="right">
                <div class="right_title">
                    <h3>{{fileLists[activeKey]}} 
                        <i style="color:red;" v-if="fileData.sealList && fileData.sealList.length">（已盖章）</i>
                        <i style="color:red;" v-else>（未盖章）</i>
                    </h3>
                    <a-popconfirm :disabled="!disableSubmit" v-if="disabled"
                        placement="bottomRight" ok-text="确认" cancel-text="取消" @confirm="againSeal">
                        <template slot="title">
                            <p>当前附件已预盖章，您确定<span style="color:red;padding:0 10px;fontSize:18px;">重新盖章</span></p>
                        </template>
                        <a-button class="btn" size="small" :loading="loading" type="primary" :disabled="!disableSubmit">重新盖章</a-button>
                    </a-popconfirm>
                    <a-popconfirm :disabled="disableSubmit|| overLoad" v-else
                        placement="bottomRight" ok-text="确认" cancel-text="取消" @confirm="confirmSave">
                        <template slot="title">
                            <p>当前附件，您一共选择了<span style="color:red;padding:0 10px;fontSize:18px;">{{els.length}}</span>处盖章</p>
                            <p>请检查并确认后盖章！</p>
                            <p style="color:red;" v-if="fileData.filePerforation">*提示：请选择打印方式（影响骑缝章）</p>
                            <a-radio-group v-model="printType" v-if="fileData.filePerforation">
                                <a-radio :value="1">单面</a-radio>
                                <a-radio :value="2">双面</a-radio>
                            </a-radio-group>
                        </template>
                        <a-button class="btn" size="small" :loading="loading" type="primary" :disabled="disableSubmit|| overLoad">保存签章</a-button>
                    </a-popconfirm>
                </div>
                <div class="right_box">
                    <p class="file_left"></p>
                    <div class="file" ref="file" id="file">
                        <j-form-container :disabled="disableSubmit" slot="detail">
                            <div class="file_box" :style="{'width':maxW ? maxW + 2 + 'px':'100%'}">
                                <div v-for="(img,index) in contacts" :key="index*10" 
                                    class="pdf_list" :id="index+1">
                                    <img :src="`${img.url}?${new Date().getTime()}`" alt="">
                                    <h4 class="page" :style="{'width':maxW + 'px'}">第{{index+1}}页</h4>
                                </div>
                                <!-- <a-icon type="close-circle" class="icon_close"/> -->
                                <div v-for="(item,index) in els" :key="item.id"  >
                                    <a-popover title=""  :visible="visibleKey ===index && visible"  @visibleChange="(e) =>handleVisibleChange(e, index)"  >
                                        <template slot="content" >
                                            <a-button-group>
                                                <a-button v-if="item.category!==1" @click="addCurrentMultiSeal(item)" >批量应用</a-button>
                                                <a-button @click="delCurrentSeal(item)" ><a-icon type="delete" /></a-button>
                                            </a-button-group>
                                        </template>
                                    <VueDragResize
                                        class="moveBox"
                                        :isActive="true" 
                                        :isResizable="false" 
                                        :parentLimitation="true"
                                        :preventActiveBehavior="true"
                                        :parentW="maxW"
                                        :parentH="(maxH+30)*contacts.length"
                                        :axis="item.category==1?'y':'both'"
                                        :z="999"
                                        :w="item.w"
                                        :h="item.h"
                                        :x="item.initX"
                                        :y="item.initY + item.scollTop"
                                        v-on:dragstop="(ev) => {return onDragstop(ev,item)}"
                                        @clicked="(ev) => {return onClicked(ev,item)}">
                                        <div class="contant" >
                                            
                                            <img :src="item.pic" :style="{'position':'absolute','left':item.pLeft+'px','width':item.category==0?item.w+'px':item.h+'px','height':item.h+'px'}">
                                        </div>
                                    </VueDragResize>
                                    </a-popover>
                                </div>
                            </div>
                        </j-form-container>
                    </div>
                    <div class="file_right">
                        <a-anchor :getContainer="getContainer" @change="anchorChange">
                            <a-anchor-link v-for="(item,index) in contacts" :key="index+'a'"
                                :href="'#'+(index+1)" :title="index+1" />
                        </a-anchor>
                        
                        <!-- <div class="load_more"
                            v-if="contacts.length>0  && contacts.length < pagination.total"
                            :style="{ textAlign: 'center', height: '30px', lineHeight: '30px' }"
                            >
                            <a-button 
                                @click="handleInfiniteOnLoad" size="small">
                                更多加载
                            </a-button>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
        <footer class="foot">Copyright © 2021 首辅工程设计有限公司 蜀ICP备2021009126号</footer>
        <a-modal v-model="show"  title="批量应用"  @ok="handleOk" okText="确定" cancelText="取消">
            <a-form-model ref="form" :model="form" :rules="rules" >
            <a-form-model-item>
                <a-select style="width:100%" v-model="form.type"  :options="typeList" placeholder="请选择"/>   
            </a-form-model-item>
            <a-form-model-item v-if="form.type===1"   props="page"  >
                <a-input v-model="form.page" />
                <p style="color:#ccc" >请输入页面,例如(3,5,7-10)</p> 
            </a-form-model-item>
        </a-form-model>
        </a-modal>
    </a-spin>
</template>

<script>
import VueDragResize from "vue-drag-resize";
import { getPngByFileName, saveSealConfig } from "@/api/api";
import { delWeight } from "@/utils/util";
// import {mapMutations,mapGetters} from 'vuex';
export default {
  name: "multiattachmentseal",
  components: {
    VueDragResize,
  },
  data() {
    return {
      disabled: false,
      spinning: false,
      loading: false,
      imgWH: {},
      cloneImgWH: {},
      maxW: 0,
      maxH: 0,
      els: [],
      //当前附件
      fileData: {},
      contacts: [],
      //分页查询
      pagination: {
        pageNo: 1,
        pageSize: 20,
        total: 0,
      },
      overLoad: true,
      //公章list=====category 0-电子章， 1- 骑缝章
      //拖拽的位置
      diffXY: {
        x: 0,
        y: 0,
      },
      //选择的附件key
      activeKey: 0,
      prevActiveKey: 0,
      //缓存配置对象
      tempData: {},
      //选择单双面
      printType: 1,
      //锚点位置
      currentActiveLink: 1,
      // 批量应用操作 
      show:false,
      visible:false,
      visibleKey:-1,
      form:{
        type:1,
        page:"",
      },
      typeList:[{value:1,label:"页面范围"},{value:2,label:"全部"},{value:3,label:"奇数页"},{value:4,label:"偶数页"}],
      rules:{
            page: [{ required: true,  message: '请输入页面', trigger: 'change' }],
      },
      multiSeal:{}  
    };
  },
  mounted() {},
  computed: {
    title() {
      return this.$route.query.title;
    },
    //章
    chapters() {
      return this.$route.query.chapters
        ? JSON.parse(this.$route.query.chapters)
        : [];
    },
    //印章id
    contractId() {
      return this.$route.query.contractId;
    },
    fileLists() {
      return this.$route.query.files ? JSON.parse(this.$route.query.files) : [];
    },
    disableSubmit() {
      return this.disabled;
    },
    // ...mapGetters(['disabled'])
  },
  watch: {
    contacts: {
      handler(val, old) {
        // this.imgWH = {
        //     w: val.length ? val[0].width : 0,
        //     h: val.length ? val[0].height : 0
        // };
        console.log("========old", old);
        let oldTempW = old.map((el) => el.width);
        let oldTempH = old.map((el) => el.height);
        let tempW = val.map((el) => el.width);
        let tempH = val.map((el) => el.height);
        // console.log('=============oldTempW',oldTempW,Math.max.apply(null, oldTempW))
        // console.log('=============oldTempH',oldTempH,Math.max.apply(null, oldTempH))
        // console.log('=============tempW',tempW,Math.max.apply(null, tempW))
        // console.log('=============tempH',tempH,Math.max.apply(null, tempH))
        this.cloneImgWH = {
          w: oldTempW,
          h: oldTempH,
          maxW: Math.max.apply(null, oldTempW), //old.length-1
          maxH: Math.max.apply(null, oldTempH), //old.length-1
        };
        this.imgWH.w = tempW;
        this.imgWH.h = tempH;
        this.maxW = Math.max.apply(null, tempW); //val.length-1
        this.maxH = Math.max.apply(null, tempH); //val.length-1
        console.log("=========this.imgWH=====1", this.imgWH);
        console.log("=========this.cloneImgWH====1", this.cloneImgWH);
        console.log("=========this.maxH====1", this.maxH);
        console.log("=========this.maxW====1", this.maxW);
      },
      // immediate:true,
      deep: true,
    },
    //切换时保存拖动过的配置
    activeKey: {
      handler(val, oldVal) {
        console.log("===========activeKey--val===0", val);
        console.log("===========activeKey--oldVal===0", oldVal);
        this.prevActiveKey = oldVal;
        this.$nextTick(() => {
          if (oldVal || oldVal == 0) {
            this.tempData[this.fileLists[oldVal]] = this.beforeHandler("old");
          }
          this.getSelFilePng(this.fileLists[val], this.contractId).then(
            (oldConfigList) => {
              this.disabled = !!oldConfigList.length;
              this.els = [];
              console.log("==========页数", this.pagination.total);
              if (this.pagination.total == 1) {
                this.overLoad = false;
              }

              if (oldConfigList.length) {
                console.log("==========回显后台配置");
                //保存过配置
                this.initConfig(oldConfigList);
              } else {
                //未保存过配置---用自己的缓存配置
                console.log("==========回显本地配置");
                let keys = Object.keys(this.tempData);
                console.log("=========keys", keys, this.fileLists[val]);
                if (keys.length && keys.indexOf(this.fileLists[val]) > -1) {
                  this.initConfig(this.tempData[this.fileLists[val]]);
                }
              }
            }
          );
        });
      },
      immediate: true,
    },
  },
  methods: {
    handleVisibleChange(visible, key){
        // visible = true / false;
        if (visible) {
            this.visible = visible
            this.visibleKey = key
        } else {
            this.visible = visible
            this.visibleKey = -1
        }
    },
    handleOk(){
        switch (this.form.type) {
            case 4:
                for(let i=1;i<=this.pagination.total;i++ ){
                    if(i%2 === 0){
                        let temp ={
                            ...this.multiSeal,
                            id:Math.random(),
                            initX:this.multiSeal.left,
                            initY:this.multiSeal.top - this.getDiff(this.multiSeal.pageNum, "new").preTop  + this.getDiff(i, "new").preTop,
                            top:this.multiSeal.top - this.getDiff(this.multiSeal.pageNum, "new").preTop  + this.getDiff(i, "new").preTop,
                            pageNum:i
                        }
                        this.els.push(temp);
                    }
                }
                break;
            case 3:
                for(let i=1;i<=this.pagination.total;i++ ){
                        if(i%2 === 1){
                            let temp ={
                                ...this.multiSeal,
                                id:Math.random(),
                                initX:this.multiSeal.left,
                                initY:this.multiSeal.top - this.getDiff(this.multiSeal.pageNum, "new").preTop  + this.getDiff(i, "new").preTop,
                                top:this.multiSeal.top - this.getDiff(this.multiSeal.pageNum, "new").preTop  + this.getDiff(i, "new").preTop,
                                pageNum:i
                            }
                            this.els.push(temp);
                        }
                    }
                break;
            case 2:
                for(let i=1;i<=this.pagination.total;i++ ){
                            let temp ={
                                ...this.multiSeal,
                                id:Math.random(),
                                initX:this.multiSeal.left,
                                initY:this.multiSeal.top - this.getDiff(this.multiSeal.pageNum, "new").preTop  + this.getDiff(i, "new").preTop,
                                top:this.multiSeal.top - this.getDiff(this.multiSeal.pageNum, "new").preTop  + this.getDiff(i, "new").preTop,
                                pageNum:i
                            }
                            this.els.push(temp);
                    }
                break;
            case 1:
                if(!this.form.page){
                    return this.$message.warning('请输入页面')
                }else{
                    try {
                        let newArr = []
                        let arr =  this.form.page.split(',')
                        arr.map((e)=>{
                          if(e.indexOf("-")>-1){
                            let range = e.split("-")
                            // console.log(range,e.indexOf("-"))
                            if(range.length>1){
                                range[0] = +range[0]
                                range[1] = +range[1]
                                let num  = range[0]>range[1]?range[1]:range[0]
                                let len = range[0]>range[1]?range[0]:range[1]
                                for(let i =num;i<=len;i++){
                                    newArr.push(i)
                                }
                            }
                            if(range.length>1 && e.indexOf("-")>-1){
                              if(!range[0]||!range[1]){
                                throw false
                              }
                            }
                          }else{
                            newArr.push(+e)
                          }  
                        })
                        let array = []
                        for (var i = 0; i < newArr.length; i++) {
                            if (array.indexOf(newArr[i]) === -1) {
                                array.push(newArr[i])
                            }
                        }
                        for(let i=0;i<array.length;i++ ){
                            let temp ={
                                ...this.multiSeal,
                                id:Math.random(),
                                initX:this.multiSeal.left,
                                initY:this.multiSeal.top - this.getDiff(this.multiSeal.pageNum, "new").preTop  + this.getDiff(array[i], "new").preTop,
                                top:this.multiSeal.top - this.getDiff(this.multiSeal.pageNum, "new").preTop  + this.getDiff(array[i], "new").preTop,
                                pageNum:array[i]
                            }
                            this.els.push(temp);
                        }  
                    } catch (error) {
                        return this.$message.warning('输入格式有误,请按正确的格式输入')   
                    }
                }
                break;
            default:
                break;
        }
        let temp = [];
        this.els.forEach((i)=>{
            let check = temp.every((j)=>{return (i.top !== j.top || i.left !== j.left)})
            check ? temp.push(i) : ''
        })
        this.els = temp
        this.show = false
        // console.log(this.els)
    },
    addCurrentMultiSeal(item){
        // console.log(item)
        if (this.contacts.length < this.pagination.total) {
          this.$notification.warning({
            message: "提示",
            description: "该文件未加载完，不能进行批量应用操作！",
            duration: 3,
          });
          return;
        }
        this.form={
            type:1,
            page:"",
        }
        this.multiSeal = item
        this.show  = true
        // 隐藏弹框
        this.visible = false;
        this.visibleKey = -1
    },
    getContainer() {
      return document.getElementById("file");
    },
    //锚点改变
    anchorChange(currentActiveLink) {
      console.log(
        "==========当前锚点",
        currentActiveLink,
        this.pagination.total
      );
      // return
      let curNum = currentActiveLink.slice(1);
      console.log("========截取的数据", curNum);
      this.currentActiveLink = curNum - 0;
      if (
        curNum &&
        (curNum - 0 === this.contacts.length ||
          curNum - 0 === this.contacts.length - 1)
      ) {
        this.handleInfiniteOnLoad();
      }
    },
    //切换获取
    tabChange(activeKey) {
      console.log("========选择的tab", activeKey);
      console.log("=========切换后的this.tempData", this.tempData);
      let keys = Object.keys(this.tempData);
      let oldAct = !!this.prevActiveKey ? this.prevActiveKey : 0;
      if (
        keys.length &&
        keys.indexOf(this.fileLists[oldAct]) > -1 &&
        !this.fileData.sign
      ) {
        this.$notification.warning({
          message: "提示",
          description: this.fileLists[oldAct] + "未保存盖章,记得去保存!",
          duration: 2,
        });
      }
      this.$refs.file.scrollTop = 30; //初始化锚点位置
      this.pagination.pageSize = 20;
      this.pagination.pageNo = 1;
      this.pagination.total = 0;
      this.overLoad = true;
      this.contacts = [];
      this.currentActiveLink = 1;
      this.maxW = 0;
      this.maxH = 0;
    },
    initConfig(oldConfig) {
      let qfEls = [];
      let gzEls = [];
      console.log("=========本地配置", oldConfig);
      oldConfig.forEach((el) => {
        if (!!this.contacts[el.page - 1]) {
          console.log("===========png的页数", el.page - 1);
          // let temp = {}
          if (el.category == 1) {
            console.log("===========其封装的页数", el.page - 1);
            let len = this.contacts.length;
            console.log("=====合同的len", len);
            if (this.printType == 1) {
              console.log("=======单页");
              //单页
              for (let i = 0; i < len; i++) {
                let temp1 = {
                  // id:i+1,
                  id: Math.random(),
                  pic: this.appointChapter(el.sealId).attachment,
                  type: el.sealId,
                  w: this.appointChapter(el.sealId).width / len,
                  h: this.appointChapter(el.sealId).height,
                  scollTop: this.imgWH.h[el.page - 1] * i + 30 * i, //签章初始位置
                  initX:
                    this.imgWH.w[el.page - 1] -
                    this.appointChapter(el.sealId).width / len,
                  initY: -(
                    el.coordinateY -
                    this.imgWH.h[el.page - 1] +
                    this.appointChapter(el.sealId).height / 2
                  ),
                  pLeft: -i * (this.appointChapter(el.sealId).width / len),
                  category: el.category,
                  pageNum: i + 1,
                  left:
                    this.imgWH.w - this.appointChapter(el.sealId).width / len,
                  top: -(
                    el.coordinateY -
                    this.imgWH.h[el.page - 1] +
                    this.appointChapter(el.sealId).height / 2
                  ),
                };
                qfEls.push(temp1);
              }
            } else {
              //双页
              console.log("=======双页");
              let printList = [];
              for (let j = 0; j <= len; j++) {
                if (j % 2 != 0) {
                  printList.push(j);
                }
              }
              console.log("=====双页打印", printList);
              let printLen = printList.length;
              for (let i = 0; i < printLen; i++) {
                console.log("=====双页打印章的i", i);
                let temp2 = {
                  // id:printList[i],
                  id: Math.random(),
                  pic: this.appointChapter(el.sealId).attachment,
                  type: el.sealId,
                  w: this.appointChapter(el.sealId).width / printLen,
                  h: this.appointChapter(el.sealId).height,
                  scollTop:
                    (this.imgWH.h[el.page - 1] + 30) * (printList[i] - 1), //签章初始位置
                  initX:
                    this.imgWH.w[el.page - 1] -
                    this.appointChapter(el.sealId).width / printLen,
                  initY: -(
                    el.coordinateY -
                    this.imgWH.h[el.page - 1] +
                    this.appointChapter(el.sealId).height / 2
                  ),
                  pLeft: -i * (this.appointChapter(el.sealId).width / printLen),
                  category: el.category,
                  pageNum: printList[i],
                  left:
                    this.imgWH.w[el.page - 1] -
                    this.appointChapter(el.sealId).width / printLen,
                  top: -(
                    el.coordinateY -
                    this.imgWH.h[el.page - 1] +
                    this.appointChapter(el.sealId).height / 2
                  ),
                };
                qfEls.push(temp2);
              }
            }
          } else {
            // coordinateY = el.top - this.getDiff(el.pageNum,str).preTop + el.h/2;
            // coordinateX = el.left - this.getDiff(el.pageNum,str).currLeft + el.w/2;
            console.log("===========公章的页数", el.page);
            let temp = {
              id: Math.random(),
              pic: this.appointChapter(el.sealId).attachment,
              type: el.sealId,
              w: this.appointChapter(el.sealId).width,
              h: this.appointChapter(el.sealId).height,
              scollTop: 0, //签章初始位置this.$refs.file.scrollTop
              initX:
                el.coordinateX -
                this.appointChapter(el.sealId).width / 2 +
                this.getDiff(el.page, "new").currLeft,
              initY:
                el.coordinateY -
                this.appointChapter(el.sealId).height / 2 +
                this.getDiff(el.page, "new").preTop,
              category: el.category,
              pageNum: el.page,
              left:
                el.coordinateX -
                this.appointChapter(el.sealId).width / 2 +
                this.getDiff(el.page, "new").currLeft,
              top:
                el.coordinateY -
                this.appointChapter(el.sealId).height / 2 +
                this.getDiff(el.page, "new").preTop,
            };
            gzEls.push(temp);
          }
        }
      });
      this.els = qfEls.concat(gzEls);
      console.log("===========回显后的数据", this.els);
    },
    appointChapter(sealId) {
      let item = this.chapters.filter((el) => el.id == sealId);
      return item[0];
    },
    //获取附件详情png
    getSelFilePng(filename, contractId) {
      this.spinning = true;
      return getPngByFileName({
        filename: filename,
        id: contractId,
        type: 2,
        pageSize: this.pagination.pageSize,
        pageNo: this.pagination.pageNo,
      })
        .then((res) => {
          if (res.success) {
            this.fileData = res.result;
            this.contacts = this.contacts.concat(res.result.png);
            this.printType =
              res.result.printType === null ? 1 : res.result.printType; //打印方式
            this.pagination.total = res.result.totalSize;
            return res.result.sealList;
          } else {
            this.$notification.error({
              message: "错误提示",
              description: res.message,
              duration: 0,
            });
            this.pagination.pageNo -= 1;
          }
        })
        .finally(() => {
          this.spinning = false;
        });
    },
    //触底操作
    handleInfiniteOnLoad() {
      console.log("=========触底;");
      const data = this.contacts;
      if (data.length == this.pagination.total) {
        this.$message.warning("图纸已加载完成!");
        this.overLoad = false;
        return;
      }
      console.log("========继续加载");
      this.pagination.pageNo += 1;
      this.getSelFilePng(this.fileLists[this.activeKey], this.contractId).then(
        (oldConfigList) => {
          this.disabled = !!oldConfigList.length;
          if (oldConfigList.length) {
            //存在过配置
            this.initConfig(oldConfigList);
          }
        }
      );
    },
    //选择的那个章
    selectDrag(ev, item) {
      console.log(ev, "eveveveve");
      console.log(
        this.$refs.file.offsetParent.offsetLeft,
        "this.$refs.file.offsetParent.offsetLeft"
      );
      ev.target.style.opacity = 0.5
      ev.dataTransfer.effectAllowed = "move"; //移动效果
      ev.dataTransfer.setData("text", ""); //附加数据，　没有这一项，firefox中无法移动
      let pngDiff = (this.$refs.file.clientWidth - this.maxW) / 2;
      pngDiff = pngDiff > 0 ? pngDiff : 0;
      console.log("=========pngDiff", pngDiff);
      this.diffXY.x =
        (ev.offsetX || ev.layerX) +
        this.$refs.file.offsetParent.offsetLeft +
        30 +
        pngDiff; //ev.clientX - ev.target.offsetLeft
      this.diffXY.y =
        (ev.offsetY || ev.layerY) + this.$refs.file.offsetParent.offsetTop + 30; //ev.clientY - ev.target.offsetTop
      return true;
    },
    //计算页数
    getPage(tempScrollTop, curActLink) {
      console.log("============getPage", tempScrollTop);
      console.log("============this.imgWH.h", this.imgWH.h);
      console.log("============this.currentActiveLink", curActLink);
      let curPage = 0;
      let tempHsumPrev = 0;
      let tempHsumNext = 0;
      if (this.imgWH.h.length > 1) {
        curActLink = curActLink || 1;
        tempHsumPrev = this.imgWH.h.slice(0, curActLink);
        tempHsumPrev = tempHsumPrev.length
          ? tempHsumPrev.reduce(function (prev, cur) {
              return prev + cur;
            }) +
            (curActLink - 1) * 30
          : 0;
        tempHsumNext =
          this.imgWH.h.slice(0, curActLink + 1).reduce(function (prev, cur) {
            return prev + cur;
          }) +
          curActLink * 30;
      } else if (this.imgWH.h.length == 1) {
        curActLink = 0;
        tempHsumPrev = 0;
        tempHsumNext = this.imgWH.h.slice(0, 1).reduce(function (prev, cur) {
          return prev + cur;
        });
      }
      if (tempScrollTop < tempHsumPrev) {
        console.log("tempScrollTop<tempHsumPrev");
        curPage = curActLink;
      } else if (tempHsumPrev < tempScrollTop && tempScrollTop < tempHsumNext) {
        console.log("=========tempHsumPrev<tempScrollTop<tempHsumNext");
        curPage = curActLink + 1;
      } else if (tempScrollTop > tempHsumNext) {
        console.log("tempScrollTop>tempHsumNext");
        curPage = curActLink + 2;
      }
      console.log(
        "========tempHsumPrev/tempHsumNext",
        tempHsumPrev + "--" + tempHsumNext
      );
      console.log("========当前拖章定位页", curPage);
      return curPage;
    },
    //选择的那个章
    selectDragEnd(ev, item) {
      console.log("选择章====结束", ev, item);
      console.log("=========this.$refs.file", this.$refs.file.scrollTop);
      console.log("=========currentActiveLink", this.currentActiveLink);
      console.log("=========this.imgWH.h", this.imgWH.h);
      console.log(
        "====ev.clientY-this.diffXY.y+this.$refs.file.scrollTop",
        ev.clientY - this.diffXY.y + this.$refs.file.scrollTop
      );
      console.log(
        "====ev.clientX-this.diffXY.x+ this.$refs.file.scrollLeft",
        ev.clientX,
        this.diffXY.x,
        this.$refs.file.scrollLeft,
        ev.clientX - this.diffXY.x + this.$refs.file.scrollLeft
      );
      ev.target.style.opacity = ""
      ev.preventDefault() || ev.stopPropagation() //不取消，firefox中会触发网页跳转到查找setData中的内容
      let tempScrollTop =
        ev.clientY - this.diffXY.y + this.$refs.file.scrollTop;
      let currentPage = this.getPage(tempScrollTop, this.currentActiveLink);
      // return
      /******
       * 修改----区分启封章与其他章
       * 启封章拖动一次后,每页都有该章
       * 其他章是每页自己拖动
       * ******/
      let temp = {};
      if (item.category == 1) {
        if (this.contacts.length < this.pagination.total) {
          this.$notification.warning({
            message: "提示",
            description: "该文件未加载完，不能操作骑缝章！",
            duration: 3,
          });
          return;
        }
        if (this.pagination.total === 1) {
          this.$notification.warning({
            message: "提示",
            description: "该文件只有一页，不能操作骑缝章！",
            duration: 3,
          });
          return;
        }
        if (!this.fileData.filePerforation) {
          this.$notification.warning({
            message: "提示",
            description: "该文件排版格式不统一，不能操作骑缝章！",
            duration: 3,
          });
          return;
        }
        let len = this.contacts.length;
        //剔除骑缝章重复数据--骑缝章只能选一次;
        this.els = this.els.filter((el) => el.category != 1);
        // console.log('=========选择骑缝章时this.els',this.els)
        for (let i = 0; i < len; i++) {
          temp = {
            // id:i+1,
            id: Math.random(),
            pic: item.attachment,
            type: item.id,
            w: item.width / len,
            h: item.height,
            scollTop: this.imgWH.h[currentPage - 1] * i + 30 * i, //签章初始位置//this.imgWH.h * i + 30*i
            initX: this.imgWH.w[currentPage - 1] - item.width / len, //this.imgWH.w - item.width/len
            initY:
              this.imgWH.h[currentPage - 1] - this.imgWH.h[currentPage - 1] / 3, //this.imgWH.h - this.imgWH.h/3
            pLeft: -i * (item.width / len),
            category: item.category,
            pageNum: i + 1,
          };
          this.els.push(temp);
        }
      } else {
        temp = {
          id: Math.random(),
          pic: item.attachment,
          type: item.id,
          w: item.width,
          h: item.height,
          scollTop: 0, //签章初始位置this.$refs.file.scrollTop
          initX: ev.clientX - this.diffXY.x + this.$refs.file.scrollLeft,
          initY: ev.clientY - this.diffXY.y + this.$refs.file.scrollTop,
          category: item.category,
          pageNum: currentPage,
        };
        this.els.push(temp);
      }
      console.log("==========this.els", this.els);
      // return
      setTimeout(() => {
        this.els.forEach((el) => {
          el.initY = el.initY + 0.001;
        });
      }, 300);
      // console.log('=========els',this.els)
    },
    //开始--close关闭操作
    onClicked(ev, item) {
      console.log("==========开始拖动", ev, item);
      return
      if (
        ev.srcElement &&
        (ev.srcElement.nodeName == "svg" || ev.srcElement.nodeName == "path")
      ) {
        let temp = [];
        if (item.category == 1) {
          temp = this.els.filter((el) => el.category != 1);
        } else {
          temp = this.els.filter((el) => el.id != item.id);
        }
        this.els = temp;
      }
    },
    // 删除当前章
    delCurrentSeal(item){
        console.log(item,'当前章')
        let temp = [];
        if (item.category == 1) {
          temp = this.els.filter((el) => el.category != 1);
        } else {
          temp = this.els.filter((el) => el.id != item.id);
        }
        this.els = temp;
    },
    //结束
    onDragstop(newRect, item) {
      console.log("=======结束", newRect, item);
      // let num = (newRect.top+item.h/2) <= this.imgWH.h[this.currentPage-1] ? 1 : Math.ceil(newRect.top/(this.imgWH.h[this.currentPage-1]+30));
      // this.currentPage = num;
      let num = this.getPage(newRect.top, item.pageNum);
      console.log("==============第几页", num);
      if (item.category == 1) {
        //启封章top/页
        let subtractNum = 0;
        this.els.forEach((el) => {
          subtractNum = newRect.top - (num - 1) * (this.imgWH.h[0] + 30);
          // console.log('===========subtractNum',subtractNum)
          // return
          if (el.category == 1) {
            el.left = newRect.left;
            el.top = subtractNum;
            // el.pageNum = num;
            el.initY = subtractNum;
          }
        });
      } else {
        //其他章的页数
        // let num = (newRect.top+item.h/2) <= this.imgWH.h ? 1 : Math.ceil(newRect.top/(this.imgWH.h+30));
        this.els.forEach((el) => {
          if (el.id == item.id) {
            el.left = newRect.left;
            el.top = newRect.top;
            el.pageNum = num;
          }
        });
      }
    },
    //获取裁剪位置
    getDiff(page, str) {
      let arrObj = str == "old" ? this.cloneImgWH : this.imgWH;
      let maxW = str == "old" ? this.cloneImgWH.maxW : this.maxW;
      let preTopArr = arrObj.h.slice(0, page - 1);
      let preTop = preTopArr.length
        ? preTopArr.reduce(function (prev, cur) {
            return prev + cur;
          }) +
          (page - 1) * 30
        : 0;
      let currLeft = (maxW - arrObj.w[page - 1]) / 2;
      console.log("=========差值y/x==maxW", maxW);
      console.log("=========差值y/x", preTop, currLeft);
      console.log("=========差值y/x====arrObj", arrObj);
      return {
        preTop,
        currLeft,
      };
    },
    //保存章时----的设置的配置
    beforeHandler(str) {
      let tempArr = [];
      let tempQf = [];
      let arrObj = str == "old" ? this.cloneImgWH : this.imgWH;
      console.log("=====参数处理前", this.els);
      console.log("=====参数处理前arrObj", arrObj);
      console.log("=====当前的imgWH", this.imgWH);
      this.els.forEach((el) => {
        let coordinateY = 0;
        let coordinateX = 0;
        if (el.category == 1) {
          coordinateY = arrObj.h[el.pageNum - 1] - el.top - el.h / 2;
          coordinateX = el.left + el.w / 2;
        } else {
          coordinateY =
            el.top - this.getDiff(el.pageNum, str).preTop + el.h / 2;
          coordinateX =
            el.left - this.getDiff(el.pageNum, str).currLeft + el.w / 2;
        }
        let temp = {
          sealId: el.type, //章的id
          applyPdfId: this.fileData.applyId,
          page: el.pageNum,
          coordinateY: coordinateY,
          // coordinateX:el.left + el.w/2,
          coordinateX: coordinateX,
          category: el.category,
          maxLen: this.contacts.length,
        };
        el.category == 1 ? tempQf.push(temp) : tempArr.push(temp);
      });
      let tempParams = delWeight(tempQf, "id").concat(tempArr);
      return tempParams;
    },
    //保存盖章
    confirmSave() {
      // console.log('=====参数处理前',this.els);
      // return
      let params = this.beforeHandler("new");
      console.log("=====参数处理后", params);
      // return
      this.spinning = true
      saveSealConfig({
        applyPdfId: this.fileData.applyId,
        printType: this.printType,
        operatorApplyElectronicSeals: params,
      }).then((res) => {
        if (res.success) {
          this.$notification.success({
            message: "成功提示",
            description: res.message,
            duration: 0,
          });
          // this.Set_disabled(true);
          //重新加载当前附件
          this.$refs.file.scrollTop = 30; //初始化锚点位置
          this.pagination.pageNo = 1;
          this.pagination.pageSize = 20;
          this.pagination.total = 0;
          this.overLoad = true;
          this.contacts = [];
          this.currentActiveLink = 1;
          this.maxW = 0;
          this.maxH = 0;
          this.getSelFilePng(
            this.fileLists[this.activeKey],
            this.contractId
          ).then((oldConfigList) => {
            this.disabled = !!oldConfigList.length;
            if (this.pagination.total == 1) {
              this.overLoad = false;
            }
            if (oldConfigList.length) {
              //存在过配置
              this.initConfig(oldConfigList);
            }
          });
        } else {
          this.$notification.error({
            message: "错误提示",
            description: res.message,
            duration: 0,
          });
        }
        this.spinning = false
      });
    },
    //重新盖章
    againSeal() {
      this.disabled = false;
    },
    // ...mapMutations(['Set_disabled'])
  },
};
</script>
<style scoped lang="less">
// .seal{overflow: hidden;}
.seal_heard {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #002b45;
  color: #fff;
  font-size: 14px;
  .title {
    flex: 1;
    text-align: center;
  }
  .btn {
    margin-right: 30px;
  }
}
.file_name {
  height: 40px;
  background-color: #f8f8f8;
  /deep/.ant-tabs-bar {
    margin: 0 !important;
  }
}
.main {
  display: flex;
  height: calc(100vh - 90px);
  .left {
    width: 20%;
    background-color: #f6f6f6;
    padding-left: 20px;
    box-sizing: border-box;
    border-right: 1px solid #ebebeb;
    .sign {
      // border-bottom: 1px solid #ebebeb;
      margin-top: 10px;
      h4 {
        height: 30px;
        line-height: 30px;
      }
      .sign_opt {
        height: calc(100vh - 140px);
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 8px;
          height: 8px;
          background-color: #f5f5f5;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #ddd;
        }
        .sign_opt_item {
          display: flex;
          flex-direction: column;
          padding: 10px;
          // height: 100px;
          position: relative;
          span {
            height: 30px;
            line-height: 30px;
            margin-right: 10px;
            color: #000;
          }
          img {
            align-self: center;
          }
        }
      }
    }
  }
  .right {
    flex: 1;
    position: relative;
    // overflow-x: auto;
    overflow-y: hidden;
    .right_title {
      position: absolute;
      width: 100%;
      height: 30px;
      top: 0;
      margin: 0;
      border-bottom: 1px solid #ebebeb;
      background-color: #f8f8f8;
      font-size: 14px;
      z-index: 1000;
      display: flex;
      align-items: center;
      justify-content: center;
      h3 {
        margin: 0;
        flex: 1;
        text-align: center;
      }
      button {
        margin-right: 20px;
      }
    }

    .right_box {
      display: flex;
      height: calc(100vh - 120px);
      // margin-top: 30px;
      .file_right,
      .file_left {
        width: 30px;
        background-color: #f8f8f8;
        padding: 0;
        margin: 0;
      }
      .file_left {
        box-shadow: 8px 0px 8px rgba(235, 235, 235, 1);
      }
      .file_right {
        width: 80px;
        // height: calc(100vh - 120px);
        margin: 30px 0 50px;
        box-shadow: -8px 0px 8px rgba(235, 235, 235, 1);
        /deep/.ant-anchor-wrapper {
          overflow-y: auto;
          max-height: calc(100vh - 180px) !important;
          margin-left: 0;
          &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
            background-color: #f5f5f5;
          }
          &::-webkit-scrollbar-thumb {
            background-color: #ddd;
          }
        }
      }
      .file {
        flex: 1;
        overflow-y: auto;
        height: calc(100vh - 120px);
        &::-webkit-scrollbar {
          width: 8px;
          height: 8px;
          background-color: #f5f5f5;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #ddd;
        }
        .file_box {
          position: relative;
          margin: 30px auto 0;
          border: 1px solid #ddd;
          .pdf_list {
            text-align: center;
            background-color: #f8f8f8;
          }
        }
        .page {
          height: 30px;
          line-height: 30px;
          margin: 0;
          padding: 0;
          text-align: center;
          background-color: #f8f8f8;
        } //bisque
        .moveBox {
          z-index: 100;
          position: absolute;
          /deep/.content-container {
            overflow: hidden;
          }
        }
        .moveBox:hover {
          cursor: pointer;
          .icon_close {
            opacity: 1;
          }
        }
        .contant {
          position: relative;
        }
        .icon_close {
          position: absolute;
          font-size: 24px;
          right: 0;
          z-index: 99;
          opacity: 0;
        }
      }
    }
    // img{width: 100%;}
  }
  
  .vdr.active:before {
   display:none;
  }
}
.foot {
  position: fixed;
  bottom: 0;
  z-index: 99999;
  width: 100%;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 12px;
  background-color: #f6f6f6;
  border-top: 1px solid #ebebeb;
}
/deep/.ant-spin-dot-item,
.ant-spin {
  color: #002b45;
}
</style>