<template>
    <a-spin tip="Loading..." :spinning="spinning" class="template">
        <header class="seal_heard">
            <span class="title">检测合同&附件预览</span>
            <div class="btn">
                <a-tooltip placement="bottomRight">
                    <template slot="title">
                        <span>直接盖章:点击进入盖章页面,拖动选择需要的章!</span>
                    </template>
                    <a-button type="primary" @click="handlerAppointSeal">去盖章</a-button>
                </a-tooltip>
            </div>
        </header>
        <div class="main"
            v-infinite-scroll="onLoadMore"
            :infinite-scroll-disabled="busy"
            :infinite-scroll-immediate="false"
            :infinite-scroll-distance="0">
            <a-tabs v-model="activeKey" size="small" @change="tabChange">
                <a-tab-pane v-for="(item,index) in fileLists" :key="index">
                    <span slot="tab" :title="item.fileName">{{item.fileName?item.fileName.slice(0,6)+'...':''}}</span>
                    <div class="contract_main_sub" v-if="pngList && pngList.length">
                        <span>当前为：{{item.fileName}} 文件预览</span>
                        <span v-if="fileData.sign" style="color:blue;">该文件已预盖章，请阅览预盖章情况。</span>
                        <span v-if="!fileData.sign" style="color:red;">该文件未盖章,可以去盖章</span>
                        <!-- 合同的pdf图片 -->
                        <ul  style="overflow:auto">
                            <li v-for="(url,index) in pngList" :key="url.url">
                                <img :src="url.url" alt="文件附件">
                                <h4 class="page" >第{{index+1}}页</h4>
                            </li>
                        </ul>
                    </div>
                </a-tab-pane>
            </a-tabs>
        </div>
        <footer class="foot">Copyright © 2021 首辅工程设计有限公司 蜀ICP备2021009126号</footer>
    </a-spin>
</template>

<script>
import {
    getTestSealFileList,
    getTestPreview,
} from '@/api/api'
import infiniteScroll from 'vue-infinite-scroll';
export default {
    name:'moreaccessories',
    data() {
        return {
            spinning:false,
            activeKey:0,
            fileLists:[],//附件列表
            fileData:{},//对应附件表的数据
            pngList:[],//对应附件表的png图片
            //分页查询
            pagination:{
                pageNo:1,
                pageSize:5,
                total:0
            },
            //加载更多
            busy:false
        }
    },
    directives: { infiniteScroll },
    mounted() {
    },
    computed:{
        reQuery() {
            return this.$route.query
        },
        contract_id() {
            return this.reQuery.id|| '1506823507850559489';
        }
    },
    watch:{
        'contract_id':{
            handler(val) {
                if(val) {
                    this.getFiles(val);
                }
            },
            immediate:true
        },
        'fileLists':{
            handler(list) {
                if(list.length) {
                    this.getSelFilePng(list[this.activeKey],this.contract_id);
                }
            },
            immediate:true
        }
    },
    methods:{
        //获取文件列表
        getFiles(contractId) {
            this.spinning = true;
            getTestSealFileList({
                id:contractId
            }).then(res => {
                if(res.success) {
                    this.spinning = false;
                    this.fileLists = res.result

                }else {
                    this.$notification.error({
                        message: '错误提示',
                        description: res.message,
                        duration: 0,
                    })
                }
            })
        },
        //选取单个附件
        tabChange(activeKey){
            console.log('========选择的tab',activeKey)
            this.pagination.pageSize = 5;
            this.pagination.pageNo = 1
            this.pagination.total = 0;
            this.pngList = []
            this.getSelFilePng(this.fileLists[activeKey]);
        },
        //获取附件详情png
        getSelFilePng(file){
            this.spinning=true
            getTestPreview(
                {
                    id:file.mainAttId,
                    type:1,
                    pageSize:this.pagination.pageSize,
                    pageNo:this.pagination.pageNo
                }
            ).then(res => {
                if(res.success) {
                    this.fileData = res.result;
                    this.pngList = this.pngList.concat(res.result.png)
                    this.pagination.total = res.result.totalSize
                }else {
                    this.$notification.error({
                        message: '错误提示',
                        description: res.message,
                        duration: 0,
                    })
                }
            }).finally(() => {
                this.spinning=false
            })
        },
        //加载更多
        onLoadMore() {
            console.log('=======加载耕读',this.fileLists[this.activeKey])
            const data = this.pngList;
            console.log('======加载进入',data.length)
            if(data.length && data.length >= this.pagination.total) {
                this.$message.warning('加载完成');
                this.busy = true;
                return
            }
            if(this.fileLists[this.activeKey]) {
                this.pagination.pageNo +=1;
                this.getSelFilePng(this.fileLists[this.activeKey],this.contract_id);
            }
        },
        //去盖章
        handlerAppointSeal() {
            this.$router.push({
                path: '/testCenter/contactSeal',
                query: {
                    files:JSON.stringify(this.fileLists),//附件名称列表
                    contractId:this.contract_id
                }
            });
        },
    }
}
</script>

<style lang="less" scoped>
.seal_heard{
    width: 100%;
    height: 50px;
    display: flex;
    justify-content:space-between;
    align-items: center;
    background-color: #002b45;
    color: #fff;
    font-size: 14px;
    .title{text-align: center;flex:1}
    .btn{margin-right: 20px;button{margin-left: 10px;}}
}
.main {
    // padding: 20px;
    height: calc(100vh - 90px);
    overflow-y: scroll;
    &::-webkit-scrollbar{
        width: 6px;
        background-color: #F5F5F5;
        
    }
    &::-webkit-scrollbar-thumb{background-color: #ddd;}
    .contract_main_sub {
        margin: 10px 0;
        text-align: center;
        >span{margin:10px;display: inline-block;font-size: 14px;color:#000;}
    }
}
.template{
    background-color: #f7f7f7;box-sizing: border-box;font-size: 12px;color:#999;
}
.foot {
    width: 100%;
    height: 40px;
    line-height: 40px;
    background-color: #f6f6f6;
    border-top: 1px solid #ebebeb;
    text-align: center;
    position: fixed;
    z-index: 99;
}
.page{width:100%;height: 30px;line-height: 30px;margin: 0;padding: 0;text-align: center;background-color:#f8f8f8;}

</style>