<template>
  <a-spin :spinning="confirmLoading">
    <div class="box">
      <h3 class="title">文件详情</h3>
      <div class="content">
        <van-cell title="文稿标题" :value="model.title"></van-cell>
        <van-cell title="文号" :value="model.fileno"></van-cell>
        <van-cell title="拟稿人" :value="model.draftername"></van-cell>
        <van-cell title="拟稿日期" :value="model.draftdate"></van-cell>
        <van-cell title="拟稿部门" :value="model.draftdeptname"></van-cell>
        <van-cell title="部门负责人" :value="model.deptleadername"></van-cell>
        <van-cell title="签发人" :value="model.signusername"></van-cell>
        <van-cell title="主题词" :value="model.mainunit"></van-cell>
        <van-cell title="抄报" :value="model.copysentname"></van-cell>
        <van-cell title="抄送" :value="model.copysenttoname"></van-cell>
        <van-cell>
            <template #title>
              <span class="custom-title">文件路径</span>
            </template>
            <template #default>
              <a style="display:block;margin:5px 0;"
                :href="model.signminiourl" rel="noopener noreferrer">
                下载预览
              </a>
            </template>
        </van-cell>
      </div>
    </div>
  </a-spin>
</template>

<script>
import {
  getqrcodedetails
} from '@/api/api'
import { 
  Cell 
} from 'vant';

export default {
    //二维码发文管理详情页
    name:'qrcodedetails',
    components:{
      [Cell.name]:Cell,
    },
    data() {
        return {
          confirmLoading:false,
          model:{}
        }
    },
    mounted() {},
    methods:{
      async getqrcodedetails(val){
        this.confirmLoading = true;
        try {
          const datas= await getqrcodedetails({id:val})
          if(datas.success){
            this.model=datas.result
            this.confirmLoading = false;
          }
        } catch (error) {
          this.$message.error(error)
          this.confirmLoading = false;
        }
      }
    },
    computed:{
        reQuery() {
          return this.$route.query
        },
        contract_id() {
          return this.reQuery.id|| 'ae6300b4-2226-4987-81f3-97f94c6aa5d9';
        }
    },
    watch:{
      'contract_id':{
          handler(val) {
            if(val) {
              this.getqrcodedetails(val);
            }
          },
          immediate:true
      }
    },
}
</script>

<style lang="less" scoped>
.title{text-align: center;padding: 5px 0;background-color:#002b45 ;color: #fff;}
.content {height: calc(100vh - 95px);overflow-y: auto;}
</style>
