<template>
    <a-spin :spinning="confirmLoading">
    <div class="box">
        <h3 class="title">合同详情</h3>
        <van-index-bar :index-list="indexList" class="content">
            <van-index-anchor index="1">一、基本信息</van-index-anchor>
            <van-cell title="工程类型" :value="model.projectType"></van-cell>
            <van-cell title="已立项经营项目" :value="model.marketProjectName"></van-cell>
            <van-cell title="甲方" :value="model.partyAname"></van-cell>
            <van-cell title="实际付款单位" :value="model.realPayUnit"></van-cell>
            <van-cell title="乙方" :value="model.partyBname"></van-cell>
            <van-cell title="分院" :value="model.productionDeptName || '暂无分院'"></van-cell>
            <van-cell title="合同名称" :value="model.name"></van-cell>
            <van-cell title="合同类型" :value="contract_type[model.contractType]"></van-cell>
            <van-cell title="合同性质" :value="nature_contract[model.contractClass]"></van-cell>
            <van-cell title="关联主合同" :value="model.mainContractName"></van-cell>
            <van-cell title="省份" :value="model.province"></van-cell>
            <van-cell title="城市" :value="model.city"></van-cell>
            <van-cell title="区县" :value="model.county"></van-cell>
            <van-cell title="签约人" :value="model.contractSignUserName"></van-cell>
            <!-- <van-cell title="签约日期" :value="model.signDate?model.signDate.slice(0,10):''"></van-cell> -->
            <van-cell title="签约地点" :value="model.signAddress"></van-cell>
            <van-cell title="合同文本状态" :value="model.contractTextState?(model.contractTextState==2?'已寄出':'已归档'):''"></van-cell>
            <van-cell title="合同寄出人" :value="model.contractTextSendUserName"></van-cell>
            <van-cell title="发票类型" :value="invoice_type[model.invoiceType]"></van-cell>
            <van-cell title="构造物" :value="model.aproject"></van-cell>
<!-- 
            <van-index-anchor index="2">二、金额信息</van-index-anchor>
            <van-cell title="合同金额（元）" :value="model.contractValue"></van-cell>
            <van-cell title="合同结算金额（元）" :value="model.contractSettlementValue"></van-cell>
            <van-cell title="合同金额大写" :value="model.contractValueDx"></van-cell>
            <van-cell title="税率" :value="contract_tax_rate[model.taxRate]"></van-cell>
            <van-cell title="税金（元）" :value="model.taxValue"></van-cell>
            <van-cell title="不含税金额（元）" :value="model.clearContractValue"></van-cell>

            <van-index-anchor index="2">三、补充协议</van-index-anchor>
            <van-cell v-if="supplements.length">
                <van-collapse v-model="activeNames" :border="false" >
                    <van-collapse-item v-for="(item,index) in supplements" :key="item.id"
                        :name="index+1+''" :title="item.name">
                        <ul v-if="supplements.length">
                            <li class="enginee">
                                <div class="enginee_msg">
                                    <van-cell title="补充协议名称" :value="item.name"></van-cell>
                                    <van-cell title="编号" :value="item.serialNumber"></van-cell>
                                    <van-cell title="合同金额（元）" :value="item.supplementaryRmbamount"></van-cell>
                                    <van-cell title="生效日期" :value="item.signDate"></van-cell>
                                    <van-cell title="经办人" :value="item.chargeUserName"></van-cell>
                                    <van-cell>
                                        <template #title>
                                            <span class="custom-title">相关资料</span>
                                        </template>
                                        <template #default>
                                            <a style="display:block;margin:5px 0;"
                                                v-for="url of item.attachment.split(',')" :key="url"
                                                :href="url" rel="noopener noreferrer">
                                                下载预览
                                            </a>
                                        </template>
                                    </van-cell>
                                </div>
                            </li>
                        </ul>
                        <p v-else style="text-align:center;">暂无数据</p>
                    </van-collapse-item>

                </van-collapse>
            </van-cell>
            
            <van-index-anchor index="2">四、其他信息</van-index-anchor>
            <van-cell title="备注" :value="model.remark"></van-cell>
            <van-cell title="合同文本pdf" v-if="model.contractList&& model.contractList.length">
                <template #default>
                    <a style="display:block;margin:5px 0;"
                        v-for="item of model.contractList" :key="item.attId"
                        :href="item.fileUrl" rel="noopener noreferrer">
                        {{item.fileName}}
                    </a> -->
                    <!-- <HUpload v-model="model.contractList" :disabled="true"></HUpload> -->
                <!-- </template> -->
            <!-- </van-cell> -->
            <!-- <van-cell title="合同文本pdf(含二维码)" v-if="model.contractList&& model.contractList.length">
                <template #default>
                    <a style="display:block;margin:5px 0;"
                        v-for="item of model.contractList" :key="item.qrCodeFileAttId"
                        :href="item.qrCodeFileUrl" rel="noopener noreferrer">
                        {{item.fileName}}
                    </a>
                </template>
            </van-cell> -->
            <!-- <van-cell v-if="signPdfFile.length">
                <template #title>
                    <span class="custom-title">电子合约(已盖章)</span>
                </template>
                <template #default>
                    <a style="display:block;margin:5px 0;"
                        v-for="(item,index) of signPdfFile" :key="index"
                        :href="item.contractAttachmentUrl" rel="noopener noreferrer">
                        {{item.contractAttachmentFileName}}
                    </a>
                </template>
            </van-cell> -->
            
        </van-index-bar>
    </div>
    </a-spin>
</template>

<script>
import { 
    IndexBar, IndexAnchor,
    Cell, CellGroup,
    Collapse, CollapseItem,
    List,
    PullRefresh
} from 'vant';
import {
    getTestContactInfo1,
    getTestContactSup1,
    contactSignPdfList1,
    getDict,
} from '@/api/api'
import {getAres,isNaNFlag} from '@/utils/util'

export default {
    components:{
        [IndexBar.name]:IndexBar,
        [IndexAnchor.name]:IndexAnchor,
        [Cell.name]:Cell,
        [CellGroup.name]:CellGroup,
        [Collapse.name]:Collapse,
        [CollapseItem.name]:CollapseItem,
        [List.name]:List,
        [PullRefresh.name]:PullRefresh,
    },
    data() {
        return {
            confirmLoading:false,
            indexList:[1, 2, 3, 4, 5, 6],
            model:{},
            //所属工程
            activeNames:['1'],
            //数据字典
            contract_type:{},//合同类型
            nature_contract:{},//合同性质
            invoice_type:{},//发票类型
            contract_tax_rate:{},//税金
            supplements:[],//补充协议
            signPdfFile:[],//电子合约已盖章
        }
    },
    computed:{
        reQuery() {
            return this.$route.query
        },
        contract_id() {
            return this.reQuery.id|| '1519148784500924418';
        }
    },
    watch:{
        'contract_id':{
            handler(val) {
                if(val) {
                    console.log('=======合同id',val)
                    this.initInfo(val);
                    this.initSupplement(val);
                    // this.getSignPdf(val)
                }
            },
            immediate:true,
            deep:true
        }
    },
    created() {
        /**
         * 获取数据字典，注意：
         * @code 是分号；隔开的，并且每一个类型必须现在上面定义，不然列表报错
         */
        this.getDictItem('contract_tax_rate;contract_type;invoice_type;nature_contract');
    },
    methods:{
        //初始化合同详情
        initInfo(id) {
            this.confirmLoading = true;
            getTestContactInfo1({id}).then(res =>{
                if(res.success) {
                    // let parameter = !!res.result.parameter ? res.result.parameter.split('/'):[];
                    // let signPdfFile = !!res.result.signPdfFile ? res.result.signPdfFile.split(','):[];
                    let province = !res.result.province?"":isNaNFlag(res.result.province)?getAres(res.result.province,'text'):res.result.province
                    let city = !res.result.city?"":isNaNFlag(res.result.city)?getAres(res.result.city,'text'):res.result.city
                    let county = !res.result.county?"":isNaNFlag(res.result.county)?getAres(res.result.county,'text'):res.result.county
                    this.model = {
                        ...this.model,
                        ...res.result,
                        province,
                        city,
                        county
                        // parameter,
                        // signPdfFile
                    };
                }else {
                    this.$notification.error({
                        message: '错误提示',
                        description: res.message,
                        duration: 0,
                    })
                }
            }).finally(() => {
                this.confirmLoading = false;
            })
        },
        // 补充协议
        initSupplement(id) {
            getTestContactSup1({id}).then(res => {
                if(res.success) {
                    this.supplements = res.result;
                }else {
                    this.$notification.error({
                        message: '错误提示',
                        description: res.message,
                        duration: 0,
                    })
                }
            })
        },
        //获取电子合约
        getSignPdf(id) {
            contactSignPdfList1({id}).then(res => {
                if(res.success) {
                    this.signPdfFile = !!res.result?res.result:[];
                }else {
                    this.$message.error(res.message);
                }
            })
        },
        //根据字典Code, 初始化字典数组
        getDictItem(code) {
            getDict({ code })
            .then((res) => {
                if (res.success) {
                    let arr = code.split(';')
                    arr.forEach(item=>{
                        res.result[item].forEach(element=>{
                            this.$set(this[item], element.value, element.text)
                        })
                    });
                }
            })
        },

    }
}
</script>

<style lang="less" scoped>
/deep/.van-index-bar__sidebar {opacity: 0;}
.title{text-align: center;padding: 5px 0;background-color:#002b45 ;color: #fff;}
.content {height: calc(100vh - 95px);overflow-y: auto;}
/deep/.van-index-anchor--sticky{position: fixed !important;}
.enginee{
    // background-color: aliceblue;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin-bottom: 10px;
    .enginee_msg{
        width: 100%;
        margin-bottom: 5px;
        display: flex;
        justify-content: space-between;
        // padding: 0 5px;
        flex-direction: column;
        flex: 1;
    }
}
</style>