<template>
    <a-spin :spinning="confirmLoading">
        <div class="box">
            <h3 class="title">报告详情</h3>
            <div class="content">
                <van-cell title="报告编号" :value="model.reportNumber"></van-cell>
                <van-cell title="项目名称" :value="model.projectName"></van-cell>
                <van-cell title="项目地址" :value="model.projectAddress"></van-cell>
                <van-cell>
                    <template #title>
                        <span class="custom-title">委托单位(业主)</span>
                    </template>
                    <template #default>
                        <span>{{model.homeowners}}(身份证:{{model.idCard}})</span>
                    </template>
                </van-cell>
                <van-cell title="排查单位" :value="'首辅工程设计有限公司'"></van-cell>
                <van-cell title="报告日期" :value="model.reportTime"></van-cell>
            </div>
        </div>
    </a-spin>
</template>

<script>
import {
    getPcdetails
} from '@/api/api'
import { 
    Cell 
} from 'vant';

export default {
    name:'pcdetails',
    components:{
        [Cell.name]:Cell,
    },
    data() {
        return {
            confirmLoading:false,
            model:{}
        }
    },
    methods:{
        async getqrcodedetails(val){
            this.confirmLoading = true;
            try {
                const datas= await getPcdetails({id:val})
                if(datas.success){
                    this.model=datas.result
                    this.confirmLoading = false;
                }
            } catch (error) {
                this.$message.error(error)
                this.confirmLoading = false;
            }
        }
    },
    computed:{
        reQuery() {
            return this.$route.query
        },
        report_id() {
            return this.reQuery.id|| '1530115069677441026';
        }
    },
    watch:{
        'report_id':{
            handler(val) {
                if(val) {
                    this.getqrcodedetails(val);
                }
            },
            immediate:true
        }
    },
}
</script>

<style lang="less" scoped>
.title{text-align: center;padding: 5px 0;background-color:#002b45 ;color: #fff;}
.content {height: calc(100vh - 95px);overflow-y: auto;}
</style>