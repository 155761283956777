<template>
    <a-spin tip="Loading..." :spinning="spinning" class="seal">
        <header class="seal_heard">
            <a-button class="back"  type="primary" @click="goBack">返回</a-button>
            <span class="title">指定红头文件签章位置</span>
            <a-popconfirm :disabled="disableSubmit"
                placement="bottomRight" ok-text="确认" cancel-text="取消" @confirm="confirmSave">
                <template slot="title">
                    <p>您一共选择了<span style="color:red;padding:0 10px;fontSize:18px;">{{els.length}}</span>处盖章</p>
                    <p>请检查并确认后盖章</p>
                </template>
                <a-button class="btn" :loading="loading" type="primary" :disabled="disableSubmit">保存签章</a-button>
            </a-popconfirm>
        </header>
        <div class="main">
            <div class="left">
                <j-form-container :disabled="disableSubmit">
                    <div class="sign">
                        <h4>印章大全</h4>
                        <div class="sign_opt">
                            <p class="sign_opt_item" v-for="item in chapters" :key="item.id" :draggable="true">
                                <span>{{item.signName}}</span>
                                <img :src="item.directions" :draggable="true"
                                    :style="{'max-width':item.width + 'px','max-height':item.height+ 'px'}"
                                    v-on:dragstart="selectDrag($event,item)"
                                    v-on:dragend="selectDragEnd($event,item)">
                                <!-- <img :src="require('@/assets/'+ item.icon)" :draggable="true" v-on:dragstart="selectDrag($event,item)"> -->
                            </p>
                        </div>
                    </div>
                </j-form-container>
            </div>
            <div class="right">
                <h3>红头文件附件</h3>
                <div class="right_box">
                    <p class="file_left"></p>
                    <div class="file" ref="file" id="file">
                        <j-form-container :disabled="disableSubmit" slot="detail">
                            <div class="file_box" :style="{'width':imgWH.w ? imgWH.w + 2 + 'px':'100%'}">
                                <div v-for="(img,index) in contacts" :key="index*10" class="pdf_list">
                                    <img :src="img.url" alt="" :id="index+1">
                                    <!-- <img :src="require('@/assets/'+img)" ref="contact" :pageNum="index+1"> -->
                                    <h4 class="page" :style="{'width':imgWH.w + 'px'}">第{{index+1}}页</h4>
                                </div>
                                <VueDragResize
                                    v-for="(item) in els" :key="item.id"
                                    class="moveBox"
                                    :isActive="true" 
                                    :isResizable="false" 
                                    :parentLimitation="true"
                                    :preventActiveBehavior="true"
                                    :parentW="imgWH.w"
                                    :parentH="(imgWH.h+30)*contacts.length"
                                    :axis="item.category==1?'y':'both'"
                                    :z="999"
                                    :w="item.w"
                                    :h="item.h"
                                    :x="item.initX"
                                    :y="item.initY + item.scollTop"
                                    v-on:dragstop="(ev) => {return onDragstop(ev,item)}"
                                    @clicked="(ev) => {return onClicked(ev,item)}">
                                    <div class="contant" >
                                        <a-icon type="close-circle" class="icon_close"/>
                                        <img :src="item.pic" :style="{'position':'absolute','left':item.pLeft+'px','width':item.category==0?item.w+'px':item.h+'px','height':item.h+'px'}">
                                        <!-- <img :src="require('@/assets/'+item.pic)"> -->
                                    </div>
                                </VueDragResize>
                            </div>
                        </j-form-container>
                    </div>
                    <div class="file_right">
                        <a-anchor :getContainer="getContainer">
                            <a-anchor-link v-for="(item,index) in contacts" :key="index+1"
                                :href="'#'+(index+1)" :title="index+1" />
                        </a-anchor>
                    </div>
                </div>
            </div>
        </div>
        <footer class="foot">Copyright © 2021 首辅工程设计有限公司 蜀ICP备2021009126号</footer>
    
    </a-spin>
</template>

<script>

import VueDragResize from 'vue-drag-resize';
import {
	OfficialDocumentsSeal,
	getChapter,
    getOfficialDocumentsPdf
} from '@/api/api';
import {delWeight} from '@/utils/util';
import {mapMutations,mapGetters} from 'vuex';
export default {
    // name:'签章',
    components:{
        VueDragResize
    },
    data() {
        return {
            spinning:false,
            loading:false,
            imgWH:{},
            els:[],
            //公章list =====category 0-电子章， 1- 骑缝章
            chapters:[],
            //拖拽的位置
            diffXY:{
                x:0,
                y:0
            },
            contacts:[]
        }
    },
    mounted() {
        this.initChapter();
        this.cloneChapter = JSON.parse(JSON.stringify(this.chapters));
    },
    computed:{
        contractId() {
            return this.$route.query.contractId
        },
        source() {
            return this.$route.query.source
        },

        disableSubmit() {
            console.log('=======disableSubmit',this.disabled)
            return this.disabled
        },
        ...mapGetters(['disabled'])
    },
    watch:{
        'contractId':{
            handler(val) {
                if(val) {
                    this.getInitPdf();
                }
            },
            immediate:true
        },
        'contacts':{
            handler(val) {
                console.log('contacts=========',val)
                if(val.length) {
                    this.imgWH = {
                        w: val.length ? val[0].width : 0,
                        h: val.length ? val[0].height : 0
                    }
                }
            },
            // immediate:true,
            deep:true
        },
    },
    methods:{
        getContainer() {
            return document.getElementById('file')
        },
        //返回
        goBack() {
            this.$router.push({
                path: '/businessPlatform/officedocument',
                query: {
                    id:this.contractId
                }
            });
        },
        // 初始化签章
        initChapter() {
            getChapter({
                pageNo:1,
                pageSize:20,
                type:'POSTING'
            }).then(res => {
                if(res.success) {
                    this.chapters = this.cloneChapter.concat(res.result.records);
                }else {
                    this.$notification.error({
                        message: '错误提示',
                        description: res.message,
                        duration: 0,
                    })
                }
            })
        },
        //获取最新pdf
        getInitPdf() {
            this.spinning = true;
            getOfficialDocumentsPdf({
                id:this.contractId
            }).then(res => {
                if(res.success) {
                    this.contacts = res.result;
                }else {
                    this.$notification.error({
                        message: '错误提示',
                        description: res.message,
                        duration: 0,
                    });
                }
            }).finally(() => {
                this.spinning=false
            })
        },
        //选择的那个章
        selectDrag(ev,item) {
            console.log('选择章====开始',ev,item,Date.parse(new Date())/1000);
            console.log('选择章====file',this.$refs);
            ev.dataTransfer.effectAllowed = "move";  //移动效果
            ev.dataTransfer.setData("text", '');  //附加数据，　没有这一项，firefox中无法移动
            let pngDiff = (this.$refs.file.clientWidth - this.imgWH.w)/2
            console.log('=========pngDiff',pngDiff)
            this.diffXY.x = (ev.offsetX || ev.layerX) + this.$refs.file.offsetParent.offsetLeft + 30 + pngDiff;//ev.clientX - ev.target.offsetLeft
            this.diffXY.y = (ev.offsetY || ev.layerY) + this.$refs.file.offsetParent.offsetTop;//ev.clientY - ev.target.offsetTop
            return true
        },
        //选择的那个章
        selectDragEnd(ev,item) {
            console.log('选择章====结束',ev,item);
            console.log('=========this.$refs.file',this.$refs.file.scrollTop,this.diffXY)
            /******
             * 修改----区分启封章与其他章
             * 启封章拖动一次后,每页都有该章
             * 其他章是每页自己拖动
             * ******/ 
            let temp = {}
            if(item.signName === '骑缝章') {
                let len = this.contacts.length;
                //剔除骑缝章重复数据--骑缝章只能选一次;
                this.els = this.els.filter(el => el.type!=='骑缝章');
                console.log('=========选择骑缝章时this.els',this.els)
                for(let i=0;i<len;i++) {
                    temp = {
                        id:i+1,
                        pic:item.directions,
                        type:item.signName,
                        w:item.width/len,
                        h:item.height,
                        scollTop:this.imgWH.h * i + 30*i,//签章初始位置
                        initX:this.imgWH.w - item.width/len,
                        initY:this.imgWH.h - this.imgWH.h/3,//ev.clientY-this.diffXY.y---改为默认值
                        pLeft:-i*(item.width/len),
                        category: 1
                    }
                    this.els.push(temp);
                }
            }else {
                temp = {
                    id:Math.random(),
                    pic:item.directions,
                    type:item.signName,
                    w:item.width,
                    h:item.height,
                    scollTop:this.$refs.file.scrollTop,//签章初始位置
                    initX:ev.clientX-this.diffXY.x+ this.$refs.file.scrollLeft,
                    initY:ev.clientY-this.diffXY.y,
                    category: 0
                }
                this.els.push(temp);
            }
            setTimeout(() => {
                this.els.forEach(el => {
                    el.initY = el.initY + 0.001;
                })
            },300)
            console.log('=========els',this.els)
        },
        //开始--close关闭操作
        onClicked(ev,item) {
            console.log('==========开始拖动',ev,item)
            // return
            if(ev.srcElement && (ev.srcElement.nodeName == 'svg' || ev.srcElement.nodeName =='path')) {
                let temp = [];
                if(item.type === '骑缝章') {
                    temp = this.els.filter(el => el.type!=='骑缝章');
                }else {
                    temp = this.els.filter(el => el.id != item.id);
                }
                // console.log('========temp',temp);
                this.els = temp;
            }
        },
        //结束
        onDragstop(newRect,item) {
            console.log('=======结束',newRect,item);
            let num = (newRect.top+item.h/2) <= this.imgWH.h ? 1 : Math.ceil(newRect.top/(this.imgWH.h+30));
            if(item.type === '骑缝章') {
                //启封章top/页
                let subtractNum = 0;
                this.els.forEach(el => {
                    subtractNum = newRect.top - (num-1)*(this.imgWH.h+30)
                    console.log('======结束subtractNum',subtractNum);
                    if(el.type === '骑缝章') {
                        el.left = newRect.left;
                        el.top = subtractNum;
                        el.pageNum = num;
                        el.initY = subtractNum;
                    }
                })
            }else {
                //其他章的页数
                this.els.forEach(el => {
                    if(el.id == item.id) {
                        el.left = newRect.left;
                        el.top = newRect.top;
                        el.pageNum = num;
                    }
                })
                console.log('屏幕滚动的高度====计算页数',this.$refs.file.scrollTop,item.h,this.imgWH.h,num);
            }
        },
        //保存盖章
        confirmSave() {
            console.log('=====参数处理前',this.els);
            // return
            let tempArr = [];
            let tempQf = [];
            this.els.forEach(el => {
                let coordinateY = 0;
                if(el.type === '骑缝章') {
                    coordinateY = this.imgWH.h - el.top - el.h/2;
                }else {
                    coordinateY = el.top - (this.imgWH.h+30)*(el.pageNum - 1) + el.h/2
                }
                let temp = {
                    postingId:this.contractId,
                    type:el.type,
                    page:el.pageNum,
                    coordinateY: coordinateY,
                    coordinateX:el.left + el.w/2,
                    category:el.category,
                    maxLen:this.contacts.length
                };
                el.type === '骑缝章' ? tempQf.push(temp):tempArr.push(temp);
            })
            let params = delWeight(tempQf,'id').concat(tempArr);
            console.log('=====参数处理后',params);
            // return
            OfficialDocumentsSeal(params).then(res => {
                if(res.success) {
                    this.$notification.success({
                        message: '成功提示',
                        description: res.message+'如无其他操作，可关闭当前页面！',
                        duration: 0,
                    });
                    this.Set_disabled(true);
                }else{
                    this.$notification.error({
                        message: '错误提示',
                        description: res.message,
                        duration: 0,
                    })
                }
            })
        },
        
        ...mapMutations(['Set_disabled'])
    }

}
</script>

<style scoped lang="less">
.seal_heard{
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #002b45;
    color: #fff;
    font-size: 14px;
    .title{flex:1;text-align: center;}
    .btn{margin-right: 30px;}
    .back{margin-left: 10px;}
}
.main {
    display: flex;
    height: calc(100vh - 80px);
    .left{
        width: 20%;
        background-color: #f6f6f6;
        padding-left: 20px;
        box-sizing: border-box;
        border-right: 1px solid #ebebeb;
        .sign {
            border-bottom: 1px solid #ebebeb;
            margin-top: 10px;
            h4{height: 30px;line-height: 30px;}
            .sign_opt {
                height: calc(100vh - 127px);
                overflow-y: auto;
                &::-webkit-scrollbar{
                    width: 8px;
                    height: 8px;
                    background-color: #F5F5F5;
                }
                &::-webkit-scrollbar-thumb{background-color: #ddd;}
                .sign_opt_item {
                    display: flex;
                    flex-direction: column;
                    // justify-content: space-between;
                    padding: 10px;
                    // height: 100px;
                    position: relative;
                    span{height: 30px;line-height: 30px;margin-right:10px;color: #000;}
                    img{align-self: center;}
                } 
            }
        }
    }
    .right{
        flex:1;
        position: relative;
        // overflow-x: auto;
        overflow-y: hidden;
        h3{position: absolute;width: 100%;top: 0; margin: 0; height: 30px;line-height: 30px;text-align: center;border-bottom: 1px solid #ebebeb;background-color: #f8f8f8;font-size: 14px;z-index: 1005;}
        .right_box {
            display: flex;
            // margin-top: 30px;
            .file_right,.file_left {
                width: 30px;
                height: calc(100vh - 80px);
                background-color: #f8f8f8;
                padding: 0;
                margin: 0;
            }
            .file_left{box-shadow:  8px 0px 8px rgba(235,235,235,1);}
            .file_right{
                width: 80px;
                // height: calc(100vh - 110px);
                margin: 30px 0 50px;
                box-shadow:  -8px 0px 8px rgba(235,235,235,1);
                /deep/.ant-anchor-wrapper {
                    overflow-y: auto;
                    max-height: calc(100vh - 150px) !important;
                    margin-left:0;
                    // padding-bottom: 100px;
                    &::-webkit-scrollbar{
                        width: 8px;
                        height: 8px;
                        background-color: #F5F5F5;
                    }
                    &::-webkit-scrollbar-thumb{background-color: #ddd;}
                }
            }
            .file {
                flex: 1;
                overflow-y: auto;
                &::-webkit-scrollbar{
                    width: 8px;
                    height: 8px;
                    background-color: #F5F5F5;
                }
                &::-webkit-scrollbar-thumb{background-color: #ddd;}
                height: calc(100vh - 80px);
                .file_box{
                    position: relative;
                    margin: 30px auto 0;
                    border: 1px solid #ddd;
                    // .pdf_list{margin-top:30px;}
                }
                .page{height: 30px;line-height: 30px;margin: 0;padding: 0;text-align: center;background-color:#f8f8f8;}//bisque
                .moveBox{z-index: 100;position: absolute;/deep/.content-container{overflow: hidden;}}
                .moveBox:hover{
                    cursor: pointer;
                    .icon_close{opacity: 1;}
                }
                .contant{position: relative;}
                .icon_close{position: absolute;font-size: 24px;right: 0;z-index: 99;opacity: 0;}
            }
        }
        // img{width: 100%;}
        
    }
}
.foot {width: 100%;height: 30px;line-height: 30px;text-align: center;font-size: 12px;background-color: #f6f6f6;border-top: 1px solid #ebebeb;}
/deep/.ant-spin-dot-item,.ant-spin{color:#002b45}
</style>