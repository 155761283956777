<template>
<div>
    <div v-if="loading" style="width:100vw;height: 100vh;display: flex;align-items: center;justify-content: center;">
        <a-spin  size="large"/>
    </div>
     
	<div class="content" v-if="!loading">
		<div class="title">
			{{curParam.provinceName}}{{curParam.month}}月工企中标额排名100强
		</div>
		<section data-support="96编辑器" data-style-id="29516">
			<section style="margin-top: 10px;margin-bottom: 10px;text-align: left;">
				<section style="background-color:#671995;border-radius:10px;">
					<section style="width:4.6rem;"><img class="rich_pages wxw-img" data-ratio="0.8032786885245902"
							data-src="https://mmbiz.qpic.cn/mmbiz_gif/Ljib4So7yuWgrC1zLpLjWjOGwhqWbFxydmNM0Aic0FnIkWN1GxdBADkx4x5icheU7Fia1PlNMgXiaszb3Wozv4nCj5g/640?wx_fmt=gif"
							data-type="gif" data-w="61" style="display: block;width: auto;" /></section>
					<section style="padding-right: 15px;padding-bottom: 15px;padding-left:15px;color: rgb(255, 255,
		                255);transform: rotateZ(0deg);">
						<p style="letter-spacing: 2px;">&nbsp; &nbsp;<span style="font-size: 15px;word-break: break-all"
								th:text="${str}">&nbsp; &nbsp;
								{{result.title}}
							</span></p>
						<!-- <p style="letter-spacing: 2px;"><span style="font-size: 15px;">&nbsp; &nbsp;&nbsp;</span><span
								style="font-size: 12px;" th:text="${dataSource}">数据来源：XX<br /></span></p> -->
						<p style="letter-spacing: 2px;" th:text="${strTime}"><span style="font-size: 12px;">&nbsp;
								&nbsp;&nbsp;&nbsp;{{result.dataSource}}</span></p>
					</section>
				</section>
			</section>
		</section>
		<section style="border-width: 0px;border-style: initial;border-color: currentcolor;font-family: &quot;microsoft
		    YaHei&quot;;margin-top: 10px;">
			<section style="height:16px;color:#8a0ad2;overflow:hidden;font-size:14px;float:left;">
				»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»»</section>
		</section>
		<table cellpadding="0" cellspacing="0" height="129" data-sort="sortDisabled">
			<colgroup>
				<col width="99" style="width:49.55pt;" />
				<col width="143" style="width:71.55pt;" />
				<col width="99" style="width:49.55pt;" />
				<col width="187" style="width:93.55pt;" />
				<col width="121" style="width:60.55pt;" span="2" />
		 </colgroup>
			<tbody>
				<tr>
					<th style="border-color: rgb(172, 57, 255);word-break: break-all;" width="32"><span style="font-size:
		                    12px;"> 排名</span></th>
					<th style="border-color: rgb(172, 57, 255);word-break: break-all;" width="26"><span style="font-size:
		                    12px;">&nbsp;企业名称</span></th>
					<th style="border-color: rgb(172, 57, 255);word-break: break-all;"><span style="font-size:
		                    12px;">&nbsp;省份</span></th>
					<th style="border-color: rgb(172, 57, 255);word-break: break-all;"><span style="font-size:
		                    12px;">&nbsp;中标合同额(万)</span></th>
					<th style="border-color: rgb(172, 57, 255);word-break: break-all;"><span style="font-size:
		                    12px;">中标数<br /></span></th>
				</tr>
				<tr height="28" style="height:14.00pt;" th:each="li:${list}" v-for="(item,index) in result.list" :key="index">
					<td height="14" style="border-color: rgb(172, 57, 255);text-align: center;" width="32"
						th:text="${li.rank}">{{item.rank}}</td>
					<td style="border-color: rgb(172, 57, 255);text-align: center;" width="174"
						th:text="${li.companyName}">{{item.companyName}}</td>
					<td style="border-color: rgb(172, 57, 255);text-align: center;" width="99" th:text="${li.province}">
						{{item.province}}</td>
					<td style="border-color: rgb(172, 57, 255);text-align: center;" width="120" th:text="${li.amount}">
						{{item.amount}}</td>
					<td style="border-color: rgb(172, 57, 255);text-align: center;" width="103"
						th:text="${li.bidCount}">{{item.bidCount}}</td>
				</tr>
			</tbody>
		</table>

		<p><br /></p>
		<!-- <table cellpadding="0" cellspacing="0" height="129" data-sort="sortDisabled" style='border-color: #000'>
			<thead style='border-color: #000'>
				<tr style='border-color: #000'>
					<th style='background-color: red;color: #fff;border-color: #000;font-size:15px;' colspan="4">
						2022年全国各省业绩
					</th>
				</tr>
			</thead>
			<tbody>
				<tr style='border-color: #000' v-for="(item,index) in province" :key="index">
					<th style="border-color: #000;word-break: break-all;" v-for="(item1,index1) in item.row" :key="index1" width="99" @click='chooseprovince(item1)'><span style="font-size:
		                   12px;color: rgb(0, 128, 255);">&nbsp;{{item1}}</span></th>
					

				</tr>
				
			</tbody>
			<tfoot>
			          <tr>
			            <td  colspan="4" style='font-size:12px;'>
			             注：全国各省分子公司业绩,蓝色为已经发布的省份，点击相关省份（市）即可直接查看。
			            </td>
			          </tr>
			        </tfoot>
		</table> -->
		<p><br /></p>
		<p><span
				style="font-size: 12px;">备注：本次数据统计来源于公司系统，信息来源真实、有效。但由于少部分交易中心未被发现，或少量工程联合体投标项目被重复统计，可能导致本次统计数据出现一定误差。</span>
		</p>
	</div>
</div>

</template>

<script>
	import {gettabledata} from "@/api/api"
	import province from './province.json'
	export default {
		data() {
			return {
				title: 'Hello',
				province:[],
				loading:false,
				result:{},
				curParam:{}
			}
		},
		 mounted() {
			
			this.curParam = this.$route.query; //获取路由参数
            console.log(this.curParam);
			this.province=province
			this.getdata(this.curParam)
			
		},
		methods: {
			chooseprovince(item){
				this.curParam.provinceName=item
				this.getdata(this.curParam)
			},
			getdata(curParam){
				this.loading=true
				
				let data={
					showNumber:curParam.showNumber,
					year:curParam.year,
					month:curParam.month,
					provinceName:curParam.provinceName=='全国' ? null:curParam.provinceName
				}
                console.log(data);
				gettabledata(data).then(res=>{
					
					this.loading=false
					this.result=res.result
					console.log(res)
				})
			}
		}
	}
</script>

<style>
	.content{
		padding: 0 16px;
	}
	.title {
		/* width: 100vw; */
		text-align: center;
		font-size: 20px;
		margin-top: 10px;
	}

	table,
	th,
	td {
		border: 1px solid rgb(172, 57, 255);
		font-weight: 500;
	}
	
</style>
