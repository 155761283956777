<template>
    <a-spin tip="Loading..." :spinning="spinning" class="seal">
        <header class="seal_heard">
            <span class="title">检测合同&附件盖章</span>
        </header>
        <div class="file_name">
            <a-tabs v-model="activeKey" size="small" @change="tabChange">
                <a-tab-pane 
                    v-for="(item,index) in fileLists" 
                    :key="index">
                    <span slot="tab" :title="item.fileName">{{item.fileName?item.fileName.slice(0,6)+'...':''}}</span>
                </a-tab-pane>
            </a-tabs>
        </div>
        <div class="main">
            <div class="left">
                <j-form-container :disabled="disableSubmit">
                    <div class="sign">
                        <h4>印章大全</h4>
                        <div class="sign_opt">
                            <p class="sign_opt_item" v-for="(item,index) in chapters" :key="index" :draggable="true">
                                <span>{{item.signName}}</span>
                                <img :src="item.directions" :draggable="true" :title="item.signName"
                                    :style="{'max-width':item.width + 'px','max-height':item.height+ 'px'}"
                                    v-on:dragstart="selectDrag($event,item)"
                                    v-on:dragend="selectDragEnd($event,item)">
                            </p>
                        </div>
                    </div>
                </j-form-container>
            </div>
            <div class="right">
                <div class="right_title">
                    <h3>{{fileLists[activeKey].fileName}} 
                        <i style="color:red;" v-if="fileData.sealList && fileData.sealList.length">（已盖章）</i>
                        <i style="color:red;" v-else>（未盖章）</i>
                    </h3>
                    <a-popconfirm :disabled="!disableSubmit" v-if="disabled"
                        placement="bottomRight" ok-text="确认" cancel-text="取消" @confirm="againSeal">
                        <template slot="title">
                            <p>当前附件已预盖章，您确定<span style="color:red;padding:0 10px;fontSize:18px;">重新盖章</span></p>
                        </template>
                        <a-button class="btn" size="small" :loading="loading" type="primary" :disabled="!disableSubmit">重新盖章</a-button>
                    </a-popconfirm>
                    <a-popconfirm :disabled="disableSubmit" v-else
                        placement="bottomRight" ok-text="确认" cancel-text="取消" @confirm="confirmSave">
                        <template slot="title">
                            <p>当前附件，您一共选择了<span style="color:red;padding:0 10px;fontSize:18px;">{{els.length}}</span>处盖章</p>
                            <p>请检查并确认后盖章！</p>
                            <p style="color:red;">*提示：打印方式（影响骑缝章）</p>
                            <a-radio-group v-model="printType" :disabled="true">
                                <a-radio :value="1">单面</a-radio>
                                <a-radio :value="2">双面</a-radio>
                            </a-radio-group>
                        </template>
                        <a-button class="btn" size="small" :loading="loading" type="primary" :disabled="disableSubmit">保存签章</a-button>
                    </a-popconfirm>
                </div>
                <div class="right_box">
                    <p class="file_left"></p>
                    <div class="file" ref="file" id="file">
                        <j-form-container :disabled="disableSubmit" slot="detail">
                            <div class="file_box" :style="{'width':imgWH.w ? imgWH.w + 2 + 'px':'100%'}">
                                <div v-for="(img,index) in contacts" :key="index*10" class="pdf_list" :id="index+1">
                                    <img :src="img.url" alt="">
                                    <h4 class="page" :style="{'width':imgWH.w + 'px'}">第{{index+1}}页</h4>
                                </div>
                                <VueDragResize
                                    v-for="(item) in els" :key="item.id"
                                    class="moveBox"
                                    :id="'move_'+item.id"
                                    :isActive="true" 
                                    :isResizable="false" 
                                    :parentLimitation="true"
                                    :preventActiveBehavior="true"
                                    :parentW="imgWH.w"
                                    :parentH="(imgWH.h+30)*contacts.length"
                                    :axis="item.category==1?'y':'both'"
                                    :z="999"
                                    :w="item.w"
                                    :h="item.h"
                                    :x="item.initX"
                                    :y="item.initY + item.scollTop"
                                    v-on:dragstop="(ev) => {return onDragstop(ev,item)}"
                                    @clicked="(ev) => {return onClicked(ev,item)}">
                                    <div class="contant" >
                                        <a-icon type="close-circle" class="icon_close"/>
                                        <img :src="item.pic" :style="{'position':'absolute','left':item.pLeft+'px','width':item.category==0?item.w+'px':item.h+'px','height':item.h+'px'}">
                                    </div>
                                </VueDragResize>
                            </div>
                        </j-form-container>
                    </div>
                    <div class="file_right">
                        <a-anchor :getContainer="getContainer" @change="anchorChange">
                            <a-anchor-link v-for="(item,index) in contacts" :key="index+'a'"
                                :href="'#'+(index+1)" :title="index+1" />
                        </a-anchor>
                        
                        <div class="load_more"
                            v-if="contacts.length>0  && contacts.length < pagination.total"
                            :style="{ textAlign: 'center', height: '30px', lineHeight: '30px' }"
                            >
                            <a-button 
                                @click="handleInfiniteOnLoad" size="small">
                                更多加载
                            </a-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer class="foot">Copyright © 2021 首辅工程设计有限公司 蜀ICP备2021009126号</footer>
    
    </a-spin>
</template>

<script>

import VueDragResize from 'vue-drag-resize';
import {
    getChapter,
    getTestPreview,
    addTestSeal
} from '@/api/api';
import {delWeight} from '@/utils/util';
// import {mapMutations,mapGetters} from 'vuex';
export default {
    name:'contactSeal',
    components:{
        VueDragResize
    },
    data() {
        return {
            disabled:false,
            spinning:false,
            loading:false,
            chapters:[],
            imgWH:{},
            els:[],
            //当前附件
            fileData:{},
            contacts:[],
            //分页查询
            pagination:{
                pageNo:1,
                pageSize:5,
                total:0
            },
            //公章list=====category 0-电子章， 1- 骑缝章
            //拖拽的位置
            diffXY:{
                x:0,
                y:0
            },
            //选择的附件key
            activeKey:0,
            //缓存配置对象
            tempData:{},
            //选择单双面
            printType:1
        }
    },
    created() {
        this.initChapter();
    },
    computed:{
        //印章id
        contractId() {
            return this.$route.query.contractId
        },
        fileLists() {
            return this.$route.query.files?JSON.parse(this.$route.query.files):[];
        },
        disableSubmit() {
            return this.disabled
        },
    },
    watch:{
        'contacts':{
            handler(val) {
                this.imgWH = {
                    w: val.length ? val[0].width : 0,
                    h: val.length ? val[0].height : 0
                }
            },
            immediate:true,
            deep:true
        },
        //切换时保存拖动过的配置
        'activeKey':{
            handler(val,oldVal) {
                console.log('===========activeKey',val,oldVal)
                this.$nextTick(() => {
                    if(oldVal || oldVal==0) {
                        this.tempData[this.fileLists[oldVal].fileName] = this.beforeHandler();
                    }
                    this.getSelFilePng(this.fileLists[val])
                    .then(oldConfigList => {
                        this.disabled = !!oldConfigList.length
                        this.els = [];
                        if(oldConfigList.length) {
                            console.log('==========回显后台配置')
                            //保存过配置
                            this.initConfig(oldConfigList);
                        }else {
                            //未保存过配置---用自己的缓存配置
                            console.log('==========回显本地配置')
                            let keys = Object.keys(this.tempData);
                            console.log('=========keys',keys,this.fileLists[val].fileName)
                            if(keys.length && keys.indexOf(this.fileLists[val].fileName)>-1) {
                                this.initConfig(this.tempData[this.fileLists[val].fileName])
                            }
                        }
                    });
                })
            },
            immediate:true
        }
    },
    methods:{
        getContainer() {
            return document.getElementById('file')
        },
        // 初始化签章
        initChapter() {
            getChapter({
                pageNo:1,
                pageSize:20,
                type:'CONTRACT',
                actionType:'yes'
            }).then(res => {
                if(res.success) {
                    let resp = res.result.records;
                    this.chapters = resp.map(el => {
                        if(el.signName=='骑缝章') {
                            el.category = 1
                        }else {
                            el.category = 0
                        }
                        return el
                    });
                }else {
                    this.$notification.error({
                        message: '错误提示',
                        description: res.message,
                        duration: 0,
                    })
                }
            })
        },
        //锚点改变
        anchorChange(currentActiveLink) {
            console.log('==========当前锚点',currentActiveLink)
            // return
            let curNum = currentActiveLink.slice(1);
            console.log('========截取的数据',curNum);
            if(curNum && (curNum-0 === this.contacts.length || curNum-0 === this.contacts.length-1)) {
                this.handleInfiniteOnLoad()
            }
        },
        //切换获取
        tabChange(activeKey){
            console.log('========选择的tab',activeKey)
            console.log('=========切换后的this.tempData',this.tempData)
            // console.log('=========fileTop',this.$refs)
            this.resetData()
        },
        initConfig(oldConfig) {
            console.log('===========oldConfig',oldConfig)
            let qfEls = [];
            let gzEls = [];
            oldConfig.forEach(el => {
                if(!!this.contacts[el.page-1]) {
                    console.log('===========png的页数',el.page-1)
                    // let temp = {}
                    if(el.category == 1) {
                        console.log('===========其封装的页数',el.page-1)
                        let len = this.contacts.length;
                        console.log('=====合同的len',len)
                        if(this.printType==1) {
                            console.log('=======单页')
                            //单页
                            for(let i=0;i<len;i++) {
                                let temp1 = {
                                    // id:i+1,
                                    id:Math.random(),
                                    pic:this.appointChapter(el.sealId).directions,
                                    type:el.sealId,
                                    w:this.appointChapter(el.sealId).width/len,
                                    h:this.appointChapter(el.sealId).height,
                                    scollTop:this.imgWH.h * i + 30*i,//签章初始位置
                                    initX:this.imgWH.w - this.appointChapter(el.sealId).width/len,
                                    initY:-(el.coordinateY-this.imgWH.h + this.appointChapter(el.sealId).height/2),
                                    pLeft:-i*(this.appointChapter(el.sealId).width/len),
                                    category: el.category,
                                    pageNum:i+1,
                                    left:this.imgWH.w - this.appointChapter(el.sealId).width/len,
                                    top:-(el.coordinateY-this.imgWH.h + this.appointChapter(el.sealId).height/2)
                                }
                                qfEls.push(temp1);
                            }
                        }else {
                            //双页
                            console.log('=======双页')
                            let printList = [];
                            for(let j=0;j<=len;j++) {
                                if(j%2 !=0) {
                                    printList.push(j);
                                }
                            }
                            console.log('=====双页打印',printList)
                            let printLen = printList.length;
                            for(let i=0;i<printLen;i++) {
                                console.log('=====双页打印章的i',i)
                                let temp2 = {
                                    // id:printList[i],
                                    id:Math.random(),
                                    pic:this.appointChapter(el.sealId).directions,
                                    type:el.sealId,
                                    w:this.appointChapter(el.sealId).width/printLen,
                                    h:this.appointChapter(el.sealId).height,
                                    scollTop:(this.imgWH.h+30) * (printList[i]-1),//签章初始位置
                                    initX:this.imgWH.w - this.appointChapter(el.sealId).width/printLen,
                                    initY:-(el.coordinateY-this.imgWH.h + this.appointChapter(el.sealId).height/2),
                                    pLeft:-i*(this.appointChapter(el.sealId).width/printLen),
                                    category: el.category,
                                    pageNum:printList[i],
                                    left:this.imgWH.w - this.appointChapter(el.sealId).width/printLen,
                                    top:-(el.coordinateY-this.imgWH.h + this.appointChapter(el.sealId).height/2)
                                }
                                qfEls.push(temp2);
                            }
                        }
                        
                    }else {
                        // coordinateY = el.top - (this.imgWH.h+30)*(el.pageNum - 1) + el.h/2
                        // el.left + el.w/2= el.coordinateX;
                        console.log('===========公章的页数',el.page)
                        let temp = {
                            id:Math.random(),
                            pic:this.appointChapter(el.sealId).directions,
                            type:el.sealId,
                            w:this.appointChapter(el.sealId).width,
                            h:this.appointChapter(el.sealId).height,
                            scollTop:0,//签章初始位置this.$refs.file.scrollTop
                            initX:el.coordinateX - this.appointChapter(el.sealId).width/2,
                            initY:el.coordinateY - this.appointChapter(el.sealId).height/2 + (this.imgWH.h+30)*(el.page - 1),
                            category: el.category,
                            pageNum:el.page,
                            left:el.coordinateX - this.appointChapter(el.sealId).width/2,
                            top:el.coordinateY - this.appointChapter(el.sealId).height/2 + (this.imgWH.h+30)*(el.page - 1)
                        }
                        gzEls.push(temp);
                    }
                }
            })
            this.els = qfEls.concat(gzEls)
            console.log('===========回显后的数据',this.els)
        },
        appointChapter(sealId) {
            let item = this.chapters.filter(el => el.id == sealId)
            return item[0]
        },
        //获取附件详情png
        getSelFilePng(file){
            this.spinning=true
            return getTestPreview(
                {
                    id:file.mainAttId,
                    type:2,
                    pageSize:this.pagination.pageSize,
                    pageNo:this.pagination.pageNo
                }
            ).then(res => {
                if(res.success) {
                    this.fileData = res.result;
                    this.contacts = this.contacts.concat(res.result.png);
                    this.printType = res.result.printType===null ? 1 : res.result.printType;//打印方式
                    this.pagination.total = res.result.totalSize;
                    return res.result.sealList
                }else {
                    this.$notification.error({
                        message: '错误提示',
                        description: res.message,
                        duration: 0,
                    })
                    this.pagination.pageNo -=1;
                }
            }).finally(() => {
                this.spinning=false
            })
        },
        //触底操作
        handleInfiniteOnLoad() {
            console.log('=========触底;')
            const data = this.contacts;
            if (data.length == this.pagination.total) {
                this.$message.warning('图纸已加载完成!');
                return;
            }
            console.log('========继续加载')
            this.pagination.pageNo +=1;
            this.getSelFilePng(this.fileLists[this.activeKey])
            .then(oldConfigList => {
                this.disabled = !!oldConfigList.length
                if(oldConfigList.length) {
                    //存在过配置
                    this.initConfig(oldConfigList);
                }
            });
        },
        //选择的那个章
        selectDrag(ev,item) {
            console.log(ev,'eveveveve');
            console.log(this.$refs.file.offsetParent.offsetLeft,'this.$refs.file.offsetParent.offsetLeft');
            ev.dataTransfer.effectAllowed = "move";  //移动效果
            ev.dataTransfer.setData("text", '');  //附加数据，　没有这一项，firefox中无法移动
            let pngDiff = (this.$refs.file.clientWidth - this.imgWH.w)/2
            pngDiff = pngDiff>0?pngDiff:0
            this.diffXY.x = (ev.offsetX || ev.layerX) + this.$refs.file.offsetParent.offsetLeft + 30 + pngDiff;//ev.clientX - ev.target.offsetLeft
            this.diffXY.y = (ev.offsetY || ev.layerY) + this.$refs.file.offsetParent.offsetTop+30;//ev.clientY - ev.target.offsetTop
            return true
        },
        //选择的那个章
        selectDragEnd(ev,item) {
            console.log('选择章====结束',ev,item);
            console.log('=========this.$refs.file',this.$refs.file.scrollTop)
            /******
             * 修改----区分启封章与其他章
             * 启封章拖动一次后,每页都有该章
             * 其他章是每页自己拖动
             * ******/ 
            let temp = {}
            if(item.category == 1) {
                let len = this.contacts.length;
                //剔除骑缝章重复数据--骑缝章只能选一次;
                this.els = this.els.filter(el => el.category!=1);
                // console.log('=========选择骑缝章时this.els',this.els)
                for(let i=0;i<len;i++) {
                    temp = {
                        // id:i+1,
                        id:Math.random(),
                        pic:item.directions,
                        type:item.id,
                        w:item.width/len,
                        h:item.height,
                        scollTop:this.imgWH.h * i + 30*i,//签章初始位置
                        initX:this.imgWH.w - item.width/len,
                        initY:this.imgWH.h - this.imgWH.h/3,//ev.clientY-this.diffXY.y
                        pLeft:-i*(item.width/len),
                        category: item.category
                    }
                    this.els.push(temp);
                }
            }else {
                temp = {
                    id:Math.random(),
                    pic:item.directions,
                    type:item.id,
                    w:item.width,
                    h:item.height,
                    scollTop:0,//签章初始位置this.$refs.file.scrollTop
                    initX:ev.clientX-this.diffXY.x+ this.$refs.file.scrollLeft,
                    initY:ev.clientY-this.diffXY.y+this.$refs.file.scrollTop,
                    category: item.category
                }
                this.els.push(temp);
            }
            setTimeout(() => {
                this.els.forEach(el => {
                    el.initY = el.initY + 0.001;
                })
            },300)
            // console.log('=========els',this.els)
        },
        //开始--close关闭操作
        onClicked(ev,item) {
            console.log('==========开始拖动',ev,item)
            // return
            if(ev.srcElement && (ev.srcElement.nodeName == 'svg' || ev.srcElement.nodeName =='path')) {
                let temp = [];
                if(item.category == 1) {
                    temp = this.els.filter(el => el.category!=1);
                }else {
                    temp = this.els.filter(el => el.id != item.id);
                }
                this.els = temp;
            }
        },
        //提示章的边界
        tipBoundary(newRectTop,page,item) {
            let maxH = this.imgWH.h*page + 30*(page-1);
            // let tempMinH = this.imgWH.h*(page-1) + 30*(page-1);
            // let minH = tempMinH>0?tempMinH:0;
            let top = newRectTop + item.h
            // console.log('=======到底了最大值',maxH)
            // console.log('=======到底了最小值',minH)
            // console.log('=======到底了item',top)
            if(top>maxH) {
                this.$notification.error({
                    message: '错误提示',
                    description: '第'+page+'页的章跨边界了，盖章&打印会有问题，请调整！',
                    duration: 2,
                    placement:'bottomRight'
                })
            }
        },
        //结束
        onDragstop(newRect,item) {
            console.log('=======结束',newRect,item);
            let num = (newRect.top+item.h/2) <= this.imgWH.h ? 1 : Math.ceil(newRect.top/(this.imgWH.h+30));
            console.log('==============第几页',num);
            this.tipBoundary(newRect.top,num,item);
            if(item.category == 1) {
                //启封章top/页
                let subtractNum = 0;
                this.els.forEach(el => {
                    subtractNum = newRect.top - (num-1)*(this.imgWH.h+30)
                    console.log('========subtractNum',subtractNum)
                    if(el.category == 1) {
                        el.left = newRect.left;
                        el.top = subtractNum;
                        el.pageNum = num;
                        el.initY = subtractNum;
                    }
                })
            }else {
                //其他章的页数
                // let num = (newRect.top+item.h/2) <= this.imgWH.h ? 1 : Math.ceil(newRect.top/(this.imgWH.h+30));
                this.els.forEach(el => {
                    if(el.id == item.id) {
                        el.left = newRect.left;
                        el.top = newRect.top;//newRect.top
                        el.pageNum = num;
                    }
                })

            }
        },
        //保存章时----的设置的配置
        beforeHandler() {
            let tempArr = [];
            let tempQf = [];
            this.els.forEach(el => {
                console.log('=========el',el,this.imgWH.h)
                let coordinateY = 0;
                if(el.category == 1) {
                    coordinateY = this.imgWH.h - el.top - el.h/2;
                }else {
                    coordinateY = el.top - (this.imgWH.h+30)*(el.pageNum - 1) + el.h/2
                }
                let temp = {
                    sealId:el.type,//章的id
                    applyPdfId:this.fileData.applyId,
                    page:el.pageNum,
                    coordinateY: coordinateY,
                    coordinateX:el.left + el.w/2,
                    category:el.category,
                    maxLen:this.contacts.length
                };
                el.category == 1 ? tempQf.push(temp):tempArr.push(temp);
            });
            let tempParams = delWeight(tempQf,'sealId').concat(tempArr);
            return tempParams
        },
        //保存盖章
        confirmSave() {
            console.log('=====参数处理前',this.els);
            // return
            let params = this.beforeHandler();
            console.log('=====参数处理后',params);
            // return
            addTestSeal({
                applyPdfId:this.fileData.applyId,
                printType:this.printType,
                contractApplyElectronicSeals:params
            }).then(res => {
                if(res.success) {
                    this.$notification.success({
                        message: '成功提示',
                        description: res.message,
                        duration: 0,
                    });
                    // this.Set_disabled(true);
                    //重新加载当前附件
                    this.resetData();
                    this.getSelFilePng(this.fileLists[this.activeKey])
                    .then(oldConfigList => {
                        this.disabled = !!oldConfigList.length
                        if(oldConfigList.length) {
                            //存在过配置
                            this.initConfig(oldConfigList);
                        }
                    });
                }else{
                    this.$notification.error({
                        message: '错误提示',
                        description: res.message,
                        duration: 0,
                    })
                }
            })
        },
        //重新盖章
        againSeal() {
            this.disabled = false;
        },
        resetData() {
            this.$refs.file.scrollTop = 30;//初始化锚点位置
            this.pagination.pageSize = 5;
            this.pagination.pageNo = 1
            this.pagination.total = 0;
            this.contacts = []
        }
        // ...mapMutations(['Set_disabled'])
    }

}
</script>

<style scoped lang="less">
// .seal{overflow: hidden;}
.seal_heard{
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #002b45;
    color: #fff;
    font-size: 14px;
    .title{flex:1;text-align: center;}
    .btn{margin-right: 30px;}
}
.file_name {
    height: 40px;
    background-color: #f8f8f8;
    /deep/.ant-tabs-bar{margin: 0 !important;}
}
.main {
    display: flex;
    height: calc(100vh - 90px);
    .left{
        width: 20%;
        background-color: #f6f6f6;
        padding-left: 20px;
        box-sizing: border-box;
        border-right: 1px solid #ebebeb;
        .sign {
            // border-bottom: 1px solid #ebebeb;
            margin-top: 10px;
            h4{height: 30px;line-height: 30px;}
            .sign_opt {
                height: calc(100vh - 140px);
                overflow-y: auto;
                &::-webkit-scrollbar{
                    width: 8px;
                    height: 8px;
                    background-color: #F5F5F5;
                }
                &::-webkit-scrollbar-thumb{background-color: #ddd;}
                .sign_opt_item {
                    display: flex;
                    flex-direction: column;
                    padding: 10px;
                    // height: 100px;
                    position: relative;
                    span{height: 30px;line-height: 30px;margin-right:10px;color: #000;}
                    img{align-self: center;}
                } 
            }
        }
    }
    .right{
        flex:1;
        position: relative;
        // overflow-x: auto;
        overflow-y: hidden;
        .right_title {
            position: absolute;
            width: 100%;
            height: 30px;
            top: 0; 
            margin: 0; 
            border-bottom: 1px solid #ebebeb;
            background-color: #f8f8f8;
            font-size: 14px;
            z-index: 1005;
            display: flex;
            align-items: center;
            justify-content: center;
            h3{margin: 0;flex:1;text-align: center;}
            button{margin-right: 20px;}
        }
        
        .right_box {
            display: flex;
            height: calc(100vh - 120px);
            // margin-top: 30px;
            .file_right,.file_left {
                width: 30px;
                background-color: #f8f8f8;
                padding: 0;
                margin: 0;
            }
            .file_left{box-shadow:  8px 0px 8px rgba(235,235,235,1);}
            .file_right{
                width: 80px;
                // height: calc(100vh - 120px);
                margin: 30px 0 50px;
                box-shadow:  -8px 0px 8px rgba(235,235,235,1);
                /deep/.ant-anchor-wrapper {
                    overflow-y: auto;
                    max-height: calc(100vh - 180px) !important;
                    margin-left:0;
                    &::-webkit-scrollbar{
                        width: 8px;
                        height: 8px;
                        background-color: #F5F5F5;
                    }
                    &::-webkit-scrollbar-thumb{background-color: #ddd;}
                }
            }
            .file {
                flex: 1;
                overflow-y: auto;
                height: calc(100vh - 120px);
                &::-webkit-scrollbar{
                    width: 8px;
                    height: 8px;
                    background-color: #F5F5F5;
                }
                &::-webkit-scrollbar-thumb{background-color: #ddd;}
                .file_box{
                    position: relative;
                    margin: 30px auto 0;
                    border: 1px solid #ddd;
                    // .pdf_list{margin-top:30px;}
                }
                .page{height: 30px;line-height: 30px;margin: 0;padding: 0;text-align: center;background-color:#f8f8f8;}//bisque
                .moveBox{z-index: 100;position: absolute;/deep/.content-container{overflow: hidden;}}
                .moveBox:hover{
                    cursor: pointer;
                    .icon_close{opacity: 1;}
                }
                .contant{position: relative;}
                .icon_close{position: absolute;font-size: 24px;right: 0;z-index: 99;opacity: 0;}
            }
        }
        // img{width: 100%;}
        
    }
}
.foot {
    position: fixed;
    bottom: 0;
    z-index: 99999;
    width: 100%;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-size: 12px;
    background-color: #f6f6f6;
    border-top: 1px solid #ebebeb;
}
/deep/.ant-spin-dot-item,.ant-spin{color:#002b45}
</style>