<template>
    <a-spin :spinning="confirmLoading">
    <div class="box">
        <h3 class="title">检测合格培训证书</h3>
        <div style="display: flex;align-items: center;justify-content: center;padding: 10px 0;">
            <img src="@/assets/logo.png" alt="" style="width: 128px;height: 80px;">
        </div>
        <van-index-bar :index-list="indexList" class="content">
            <!-- <van-index-anchor index="1">一、基本信息</van-index-anchor> -->
            <van-cell title="姓名" :value="model.realname"></van-cell>
            <van-cell title="身份证号" :value="model.idNumber"></van-cell>
            <van-cell title="公司" :value="model.companyName"></van-cell>
            <van-cell title="资格证书编号" :value="model.certificateNumber"></van-cell>
            <van-cell title="发证时间" :value="model.issueDate"></van-cell>
            <van-cell title="检测类别" ></van-cell>         
            <van-cell :title="model.detectCategory" ></van-cell>         
        </van-index-bar>
    </div>
    </a-spin>
</template>

<script>
import { 
    IndexBar, IndexAnchor,
    Cell, CellGroup,
    Collapse, CollapseItem,
    List,
    PullRefresh
} from 'vant';
import {
    getqueryByIdNumber,
    
} from '@/api/api'


export default {
    components:{
        [IndexBar.name]:IndexBar,
        [IndexAnchor.name]:IndexAnchor,
        [Cell.name]:Cell,
        [CellGroup.name]:CellGroup,
        [Collapse.name]:Collapse,
        [CollapseItem.name]:CollapseItem,
        [List.name]:List,
        [PullRefresh.name]:PullRefresh,
    },
    data() {
        return {
            confirmLoading:false,
            indexList:[1, 2, 3, 4, 5, 6],
            model:{},
            //所属工程
            activeNames:['1'],
            //数据字典
            contract_type:{},//合同类型
            nature_contract:{},//合同性质
            invoice_type:{},//发票类型
            contract_tax_rate:{},//税金
            supplements:[],//补充协议
            signPdfFile:[],//电子合约已盖章
        }
    },
    computed:{
        reQuery() {
            return this.$route.query
        },
        idNumber() {
            return this.reQuery.idNumber|| '430623200006096116';
        }
    },
    watch:{
        'idNumber':{
            handler(val) {
                if(val) {
                    console.log('=======合同id',val)
                    this.initInfo(val);
                    this.initSupplement(val);
                    // this.getSignPdf(val)
                }
            },
            immediate:true,
            deep:true
        }
    },
    created() {
        /**
         * 获取数据字典，注意：
         * @code 是分号；隔开的，并且每一个类型必须现在上面定义，不然列表报错
         */
        // this.getDictItem('contract_tax_rate;contract_type;invoice_type;nature_contract');
    },
    methods:{
        //初始化合同详情
        initInfo(idNumber) {
            this.confirmLoading = true;
            getqueryByIdNumber({idNumber}).then(res =>{
                if(res.success) {
                    // let parameter = !!res.result.parameter ? res.result.parameter.split('/'):[];
                    // let signPdfFile = !!res.result.signPdfFile ? res.result.signPdfFile.split(','):[];
                   
                    this.model = {
                        ...this.model,
                        ...res.result,
                       
                    };
                }else {
                    this.$notification.error({
                        message: '错误提示',
                        description: res.message,
                        duration: 0,
                    })
                }
            }).finally(() => {
                this.confirmLoading = false;
            })
        },
      

    }
}
</script>

<style lang="less" scoped>
/deep/.van-index-bar__sidebar {opacity: 0;}
.title{text-align: center;padding: 5px 0;background-color:#002b45 ;color: #fff;}
.content {height: calc(100vh - 95px);overflow-y: auto;}
/deep/.van-index-anchor--sticky{position: fixed !important;}
.enginee{
    // background-color: aliceblue;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin-bottom: 10px;
    .enginee_msg{
        width: 100%;
        margin-bottom: 5px;
        display: flex;
        justify-content: space-between;
        // padding: 0 5px;
        flex-direction: column;
        flex: 1;
    }
}
/deep/ .van-cell__value{
    color: #000;
}
</style>