<template>
  <a-spin :spinning="confirmLoading">
    <div class="box">
      <h3 class="title">用印申请详情</h3>
      <div class="content">
        <!-- <van-cell title="部门" :value="model.deptidname"></van-cell> -->
        <van-cell title="申请人" :value="model.applyuseridname"></van-cell>
        <van-cell title="印章类型" :value="model.sealtype"></van-cell>
        <van-cell title="是否需要寄送" :value="model.yesorno=='0'?'不需要':'需要'"></van-cell>
        <van-cell title="申请事由" :value="model.applyreason"></van-cell>
        <!-- <van-cell title="收件人信息" :value="model.phonenumber"></van-cell> -->
      </div>
    </div>
  </a-spin>
</template>

<script>

import { 
  Cell
} from 'vant';
import {
getFileLists
} from '@/api/api'
export default {
    name:'usingtheapplication',//二维码查询用印申请详情页面
    components:{
      [Cell.name]:Cell,

    },
    data() {
        return {
          model:{},
          confirmLoading:false
        }
    },
    mounted() {
        // this.getqrcodedetails()
        
    },
    methods:{
      async getMultiAttachmentSealList(val){
        this.confirmLoading = true;
        try {
          const datas= await getFileLists({id:val})
          if(datas.success){
            this.model=datas.result;
            this.confirmLoading = false;
          }
        } catch (error) {
          this.$message.error(error)
          this.confirmLoading = false;
        }
      }
    },
    computed:{
        reQuery() {
          return this.$route.query
        },
        contract_id() {
          return this.reQuery.id|| 'ae6800f4-a0a9-45b3-9106-85bd8dfea90b';
        }
    },
    watch:{
        'contract_id':{
            handler(val) {
                if(val) {
                    this.getMultiAttachmentSealList(val);
                }
            },
            immediate:true
        }
    }, 
}
</script>

<style lang="less" scoped>
.content {height: calc(100vh - 95px);overflow-y: auto;}
.title{text-align: center;padding: 5px 0;background-color:#002b45 ;color: #fff;}
</style>
