<template>
    <a-spin tip="Loading..." :spinning="spinning" class="seal" >
        <header class="seal_heard">
            <span class="title">PDF盖章</span>
            <!-- <a-popconfirm 
            :disabled="disableSubmit"
            placement="bottomRight" 
            ok-text="确认" 
            cancel-text="取消" 
            @confirm="confirmSave"
            title="确定要保存签章吗?"
            >
                <template slot="title">
                    <p>您一共选择了
                        <span style="color:red;padding:0 10px;fontSize:18px;">{{sealLen+signLen}}</span>处盖章，
                        其中包含<i style="color:red;padding:0 10px;fontSize:16px;">{{sealLen}}</i>处章，
                        <i style="color:red;padding-right:10px;fontSize:16px;">{{signLen}}</i>处签字。
                    </p>
                    <p>请选择需要盖章及签字的页</p>
                    <p :style="{ borderBottom: '1px solid #E9E9E9' }">
                        <a-checkbox :indeterminate="indeterminate" :checked="checkAll" @change="onCheckAllChange">
                            全选
                        </a-checkbox>
                    </p>
                    <a-checkbox-group v-model="checkedList" :options="plainOptions" @change="onChange"/>
                    <p style="margin-top:20px; color:red;fontSize:16px;">请检查并确认后盖章</p>
                </template>
                <a-button class="btn" :loading="loading" type="primary" :disabled="disableSubmit" @click.self="handlerSave">保存签章</a-button>
            </a-popconfirm> -->
        </header>
 <div class="file_name">
             <a-tabs v-model="activeKey" size="small" @change="tabChange">
                <a-tab-pane v-for="(e,index) in fileLists" :key="index">
                  <span slot="tab" :title="e.size">{{e.size.slice(0,6)+'...'}}</span>
                </a-tab-pane>  
             </a-tabs>
 </div>
           <div class="main">
            <div class="left">
                <j-form-container :disabled="disableSubmit">
                    <div class="sign">
                        <h4>印章大全</h4>
                        <div class="sign_opt">
                            <p class="sign_opt_item" v-for="item in chapters" :key="item.id" :draggable="true">
                                <!-- <span>{{item.signName}}</span> -->
                                <img :style="{width:'100%','max-width':item.width+'px',height: 'fit-content'}"
                                    :src="item.directions" :title="item.signName"
                                    :draggable="true"
                                    v-on:dragstart="selectDrag($event,item)"
                                    v-on:dragend="selectDragEnd($event,item)">
                                    <!--  -->
                                <!-- <img :src="require('@/assets/'+ item.icon)" :draggable="true" v-on:dragstart="selectDrag($event,item)"> -->
                            </p>
                        </div>
                        <h4>签字人</h4>
                        <div class="sign_opt">
                            <p class="sign_opt_item" v-for="(item,index) in signImages" :key="index" :draggable="true">
                                <!-- <span>{{item.signName}}</span> -->
                                <img  style="height: fit-content;" :title="item.name"
                                    :src="item.signUrl" 
                                    :draggable="true" 
                                    v-on:dragstart="signSelectDrag($event,item)"
                                    v-on:dragend="signSelectDragEnd($event,item)">
                                <!-- <img :src="require('@/assets/'+ item.icon)" :draggable="true" v-on:dragstart="selectDrag($event,item)"> -->
                            </p>
                        </div>
                    </div>
                </j-form-container>
            </div>
            <div class="right">
                <h3>
                  <p style="float: left;text-align: right;width:50%;">图纸</p> 
                    <a-popconfirm 
                  :disabled="disableSubmit"
                  placement="bottomRight" 
                  ok-text="确认" 
                  cancel-text="取消" 
                  @confirm="confirmSave"
                  title="确定要保存签章吗?"
                  >
      
                <a-button class="btn" style="float: right;text-align: right;" :loading="loading" size="small" type="primary" :disabled="disableSubmit" @click.self="handlerSave">保存签章</a-button>
                </a-popconfirm>
                  </h3>
                <div class="right_box">
                    <p class="file_left"></p>
                    <div class="file" ref="file" id="file">
                        <j-form-container :disabled="disableSubmit" slot="detail">
                            <div class="file_box" :style="{'width':imgWH.w ? imgWH.w + 2 + 'px':'100%' }">
                            <!-- <div class="file_box" > -->
                                <!-- <div v-for="(img,index) in contacts" :key="index*10">
                                    <img :src="img.url" alt="" :id="index+1">
                                    <h4 class="page" :style="{'width':imgWH.w + 'px'}">第{{index+1}}页</h4>
                                </div> -->

                                <a-list class="pdf_list"
                                    item-layout="vertical"
                                    :data-source="contacts">
                                    <a-list-item slot="renderItem" slot-scope="item, index" style="overflow-x:auto">
                                        <img  :src="item.url" alt="" :id="index+1">
                                        <h4 class="page" :style="{'width':imgWH.w + 'px'}">第{{index+1}}页</h4>
                                    </a-list-item>
                                </a-list>
                                <!-- <div class="pdf_list" style="width: 1000px;"> 
                                  <div v-for="(e,i) in contacts " :key="i" style="overflow-x:auto ">
                                        <img  :src="e.url" alt="text" :id="i+1">
                                        <h4 class="page" :style="{'width':imgWH.w + 'px'}">第{{index+1}}页</h4>
                                  </div>
                                </div> -->

                                <VueDragResize
                                    v-for="(item) in els" :key="item.id"
                                    class="moveBox"
                                    :isActive="true" 
                                    :isResizable="false" 
                                    :parentLimitation="true"
                                    :parentW="imgWH.w"
                                    :parentH="(imgWH.h+30)*contacts.length"
                                    :z="999"
                                    :w="item.w"
                                    :h="item.h"
                                    :x="item.initX"
                                    :y="item.initY"
                                    v-on:dragstop="(ev) => {return onDragstop(ev,item)}"
                                    @clicked="(ev) => {return onClicked(ev,item)}">
                                    <div class="contant" >
                                        <a-icon type="close-circle" class="icon_close"/>
                                        <img :src="item.pic" :style="{width:'100%',height:item.diff=='sign'?'33px':''}" :title="item.name">
                                        <!-- 'position':'absolute','left':item.pLeft+'px', -->
                                        <!-- <img :src="require('@/assets/'+item.pic)"> -->
                                    </div>
                                </VueDragResize>
                            </div>
                        </j-form-container>
                    </div>
                    <div class="file_right">
                        <a-anchor v-if="contacts.length" :getContainer="getContainer">
                            <a-anchor-link v-for="(item,index) in contacts" :key="index+1"
                                :href="'#'+(index+1)" :title="index+1" />
                        </a-anchor>
                        
                        <a-button @click="handleInfiniteOnLoad" size="small" v-if="contacts.length>0  && contacts.length < total">
                            更多加载
                        </a-button>
                    </div>
                </div>
            </div>
           </div>
               
       
    </a-spin>
</template>

<script>

import VueDragResize from 'vue-drag-resize';
import {delWeight} from '@/utils/util';

import {
	savePdfSignature,
	getPdfChapter,
    getPdfs,
    getpdfSealsParam,
    getPdfTotal
} from '@/api/api';
export default {
    name:'pdfSeal',
    components:{
        VueDragResize
    },
    props:{
        // totalObj:{
        //     type:Object,
        //     default:() => {}
        // },
    },
    data() {
        return {
          totalObj:{
            total:0,
            tmpFile:''
          },
            activeKey:0,
            prevActiveKey:0,
           fileLists:[],
            spinning:false,
            loading:false,
            imgWH:{},
            els:[],
            //公章list 
            chapters:[],
            //签字list
            signImages:[],
            //合同
            contacts:[],
            //旧的盖章配置
            oldSealConfig:[],
            oldSignConfig:[],
            disabled:false,
            //更多
            pagination:{
                pageNo:1,
                pageSize:10
            },
            //签字页码
            checkedList:[],
            indeterminate: true,
            checkAll: false,
            plainOptions:[],
            signStr:'',
            //提交保存的章&签字的长度
            sealLen:0,
            signLen:0,
            //拖拽的位置
            diffXY:{
                x:0,
                y:0
            },
            elsList:[],
            tempData:{},
        }
    },
    created() {
        //获取签章数据
        // this.initChapter();
       
        
        // this.cloneChapter = JSON.parse(JSON.stringify(this.chapters));
    },
    computed:{
        contractId() {
            return this.$route.query.id
        },
        disableSubmit() {
            // console.log('=======disableSubmit',this.disabled)
            return this.disabled
        },
        total() {
            return this.totalObj.total;
        },

    },
    watch:{
        'contacts':{
            handler(val) {
                console.log('contacts=========',val)
                
                  this.imgWH = {
                    w: val.length ? val[0].width : 0,
                    h: val.length ? val[0].height : 0
                }
                
               
            },
            immediate:true,
            deep:true
        },
        'total':{
            handler(val) {
                console.log('======总页数',val);
                if(val>0) {
                    //获取签章数据
                    // this.initChapter(this.fileLists[this.activeKey]).then(flag => {
                    //   console.log(flag,'flag');
                    //     if(flag) {
                    //         //设置页码
                    //         let temp = []
                    //         for(let i=parseInt(val)+1;--i;i>1) {
                    //             temp.push(`${i}`);
                    //         }
                    //         this.plainOptions = temp.reverse();
                    //         //加载图纸--初始化
                    //         this.fetchData(res => {
                    //           console.log(res,'res');
                    //             this.contacts = !!res.pdfImages?res.pdfImages:[];
                    //             //初始化旧配置(盖章&签字)回显
                    //             let oldConfig = !!res.config?JSON.parse(res.config.config):{};
                    //             let oldConfigLen = Object.keys(oldConfig).length;
                    //             this.oldSealConfig = oldConfigLen>0?oldConfig.dragSealList:[];
                    //             this.oldSignConfig = oldConfigLen>0?oldConfig.dragSignList:[];
                    //             //初始化旧配置(盖章&签字签字的页)回显
                    //             this.checkedList = oldConfigLen>0?oldConfig.signPages?oldConfig.signPages.split(','):[]:[];
                    //             this.checkAll = this.plainOptions.length === this.checkedList.length
                    //             this.indeterminate = !!this.checkedList.length && this.checkedList.length < this.plainOptions.length;
                    //         });
                    //     }
                    // });
                    
                }
            },
            immediate:true
        },
        'oldSealConfig':{
            immediate:true,
            deep:true,
            handler(val) {
                if(val.length>0) {
                    this.initChapterConfig(val);
                }
            }
        },
        'oldSignConfig':{
            immediate:true,
            deep:true,
            handler(val) {
                if(val.length>0) {
                    this.initSignConfig(val);
                }
            }
        },
        'contractId':{
          handler(val,oldVal) {
                 getpdfSealsParam({id:this.contractId}).then(res=>{
                      console.log(res,'res');
                      if(res.success){
                      
                      this.fileLists=res.result
                       
                        
                      }
                    })
          },
            immediate:true

        },
        'fileLists':{
          handler(val,oldVal) {
            if(val.length>0){
            this.getTotalPage(this.fileLists[this.activeKey])
            this.initChapter(this.fileLists[this.activeKey]).then(flag => {
                      console.log(flag,'flag');
                        if(flag) {
                            //设置页码
                            let temp = []
                            for(let i=parseInt(this.totalObj.total)+1;--i;i>1) {
                                temp.push(`${i}`);
                            }
                            this.plainOptions = temp.reverse();
                            //加载图纸--初始化
                            this.fetchData(res => {
                              console.log(res,'res');
                                this.contacts = !!res.pdfImages?res.pdfImages:[];
                                this.els=[]
                                this.oldSealConfig=[]
                                this.oldSignConfig=[]
                                if(res.config){
                                //初始化旧配置(盖章&签字)回显
                                let oldConfig = !!res.config?JSON.parse(res.config.config):{};
                                let oldConfigLen = Object.keys(oldConfig).length;
                                this.oldSealConfig = oldConfigLen>0?oldConfig.dragSealList:[];
                                this.oldSignConfig = oldConfigLen>0?oldConfig.dragSignList:[];
                                //初始化旧配置(盖章&签字签字的页)回显
                                this.checkedList = oldConfigLen>0?oldConfig.signPages?oldConfig.signPages.split(','):[]:[];
                                this.checkAll = this.plainOptions.length === this.checkedList.length
                                this.indeterminate = !!this.checkedList.length && this.checkedList.length < this.plainOptions.length;
                                }else{
                                   //未保存过配置---用自己的缓存配置
                            console.log('==========回显本地配置')
                            let keys = Object.keys(this.tempData);
                            console.log('=========keys',keys,this.activeKey)

                            if(keys.length && keys.indexOf(String(this.activeKey))>-1) {
                                this.els=this.tempData[Number(this.activeKey)]

                                console.log(this.els,'this.elsthis.els');
                            }
                                }
                               
                            });
                        }
                    });
            }
          },
            immediate:true

        },
          //切换时保存拖动过的配置
        'activeKey':{
            handler(val,oldVal) {
                console.log('===========activeKey--val===0',val)
                console.log('===========activeKey--oldVal===0',oldVal)
                console.log(this.fileLists,'this.fileLists');
                    if(oldVal || oldVal==0) {
                        this.tempData[oldVal] = this.els;
                    }
                    this.$nextTick(()=>{
                      if(this.fileLists.length==0) return
                      this.getTotalPage(this.fileLists[val])
                      this.initChapter(this.fileLists[val]).then(flag => {
                      console.log(flag,'flag');
                        if(flag) {
                            //设置页码
                            let temp = []
                            for(let i=parseInt(this.totalObj.total)+1;--i;i>1) {
                                temp.push(`${i}`);
                            }
                            this.plainOptions = temp.reverse();
                            //加载图纸--初始化
                            this.fetchData(res => {
                              console.log(res,'res');
                                this.contacts = !!res.pdfImages?res.pdfImages:[];
                                this.els=[]
                                this.oldSealConfig=[]
                                this.oldSignConfig=[]
                                if(res.config){
                                //初始化旧配置(盖章&签字)回显
                                let oldConfig = !!res.config?JSON.parse(res.config.config):{};
                                let oldConfigLen = Object.keys(oldConfig).length;
                                this.oldSealConfig = oldConfigLen>0?oldConfig.dragSealList:[];
                                this.oldSignConfig = oldConfigLen>0?oldConfig.dragSignList:[];
                                //初始化旧配置(盖章&签字签字的页)回显
                                this.checkedList = oldConfigLen>0?oldConfig.signPages?oldConfig.signPages.split(','):[]:[];
                                this.checkAll = this.plainOptions.length === this.checkedList.length
                                this.indeterminate = !!this.checkedList.length && this.checkedList.length < this.plainOptions.length;
                                }else{
                                   //未保存过配置---用自己的缓存配置
                            console.log('==========回显本地配置')
                            let keys = Object.keys(this.tempData);
                            console.log('=========keys',keys,val)

                            if(keys.length && keys.indexOf(String(val))>-1) {
                                this.els=this.tempData[Number(val)]

                                console.log(this.els,'this.elsthis.els');
                            }
                                }
                               
                            });
                        }
                    });
                    
                    
                    })
                  
                
                    
                this.prevActiveKey = oldVal;
                   //获取签章数据
                   
                   
                   
         
            },
            // immediate:true
        }

    },
    methods:{
      // //保存章时----的设置的配置
        // beforeHandler(str) {
        //     let tempArr = [];
        //     let tempQf = [];
        //     let arrObj = str=='old'?this.imgWH:this.imgWH;
        //     console.log('=====参数处理前',this.els);
        //     console.log('=====参数处理前arrObj',arrObj);
        //     console.log('=====当前的imgWH',this.imgWH);
        //     this.els.forEach(el => {
        //         let coordinateY = 0;
        //         let coordinateX = 0;
        //         if(el.category == 1) {
        //             coordinateY = arrObj.h[el.pageNum-1] - el.top - el.h/2;
        //             coordinateX = el.left + el.w/2;
        //         }else {
        //             // coordinateY = el.top - this.getDiff(el.pageNum,str).preTop + el.h/2;
        //             // coordinateX = el.left - this.getDiff(el.pageNum,str).currLeft + el.w/2;
        //         }
        //         let temp = {
        //             sealId:el.type,//章的id
        //             // applyPdfId:this.fileData.applyId,
        //             page:el.pageNum,
        //             coordinateY: coordinateY,
        //             // coordinateX:el.left + el.w/2,
        //             coordinateX: coordinateX,
        //             category:el.category,
        //             maxLen:this.contacts.length
        //         };
        //         el.category == 1 ? tempQf.push(temp):tempArr.push(temp);
        //     });
        //     let tempParams = delWeight(tempQf,'id').concat(tempArr);
        //     return tempParams
        // },
        //选取单个附件
        tabChange(activeKey){
            console.log('========选择的tab',activeKey)
            this.pagination.pageSize = 5;
            this.pagination.pageNo = 1
            this.pagination.total = 0;
            this.pngList = []
            this.$nextTick(()=>{
            this.activeKey=activeKey

            })
            this.disabled=false
            // this.initChapter(this.fileLists[activeKey]);
          // this.getTotalPage(this.fileLists[activeKey])

            // this.elsLis[activeKey]=this.els
            // this.$nextTick(()=>{
            //   this.els=[]
            // })
          
        },
        getContainer() {
            return document.getElementById('file')
        },
        //初始化---已盖章配置
        initChapterConfig(chapter) {
            console.log('===========章的旧配置',chapter);
                    // signName:el.type,
                    // page:el.pageNum,
                    // y: coordinateY=el.top - (this.imgWH.h+30)*(el.pageNum - 1) + el.h/2,
                    // x:el.left + el.w/2
            chapter.forEach(el => {
                if(!!this.contacts[el.page-1]) {
                    let scollTop = el.y - this.appointChapter(el.signName,'chapter').height/2 + (this.imgWH.h+30)*(el.page - 1) + 30
                    // console.log('=========id',Math.random())
                    let temp = {
                        id:Math.random(),
                        pic:this.appointChapter(el.signName,'chapter').directions,
                        type:this.appointChapter(el.signName,'chapter').signName,
                        w:this.appointChapter(el.signName,'chapter').width,
                        h:this.appointChapter(el.signName,'chapter').height,
                        // scollTop:scollTop,//签章初始位置
                        initX:el.x - this.appointChapter(el.signName,'chapter').width/2,
                        initY:scollTop,
                        left:el.x - this.appointChapter(el.signName,'chapter').width/2,
                        top:scollTop,
                        pageNum:el.page,
                        diff:'chapter',
                        name:this.appointChapter(el.signName,'chapter').signName
                    };
                    if(!el.selFlag) {
                        el.selFlag = 'yes';
                        this.els.push(temp);
                    }
                }
            });
            // console.log('===============章-----els',this.els);
            // console.log('===============章-----皆配置',chapter);
        },
        //初始化---已签字配置
        initSignConfig(sign) {
            console.log('===========签字的旧配置',sign);      
        // id:el.id,--签字地id
        // page:el.pageNum,---图纸的页数
        // y: coordinateY=this.imgWH.h - (el.top - (this.imgWH.h+30)*(el.pageNum - 1)) - el.h/2 - 17,
        // x:el.left + el.w/2 -30
            sign.forEach(el => {
                if(!!this.contacts[el.page-1]) {
                    let scollTop = el.y - this.imgWH.h - (this.imgWH.h + 30)*(el.page - 1) + 34 -30;
                    // console.log('=========id',Math.random())
                    console.log(el,'elelel');
console.log(this.imgWH,'this.imgWH');
                    console.log(scollTop,'scollTop');
                    let temp = {
                        id:Math.random(),
                        pic:this.appointChapter(el.id,'sign').signUrl,
                        type:this.appointChapter(el.id,'sign').id,
                        w:60,
                        h:34,
                        // scollTop:-scollTop,//签章初始位置
                        initX:el.x - 60/2 + 30,
                        initY:-scollTop,
                        left:el.x - 60/2 + 30,
                        top:-scollTop,
                        pageNum:el.page,
                        diff:'sign',
                        name:this.appointChapter(el.id,'sign').name
                    };
                    if(!el.selFlag) {
                        el.selFlag = 'yes';
                        this.els.push(temp);
                    }
                }
            });
            // console.log('===============签字-----els',this.els);
            // console.log('===============签字-----皆配置',sign);
        },
        //指定章
        appointChapter(spot,str) {
            let item = this.chapters.filter(el => el.signName == spot);
            let el = this.signImages.filter(el => el.id == spot);
            // console.log('=======appointChapter匹配',spot,str,item)
            // console.log('=======appointChapter匹配',spot,str,el)
            return str=='chapter'?item[0]:el[0];
        },
        // 初始化签章
        initChapter(data) {
          console.log(data,'data');
            this.spinning = true;
            return getPdfChapter({id:data.id,fileUrl:data.fileUrl,major:data.major}).then(res => {
              console.log(123);
                if(res.success) {
                    this.chapters = res.result.sealConfigs;
                    this.signImages = res.result.signImages;
                    return res.success
                }else {
                    this.$notification.error({
                        message: '错误提示',
                        description: res.message,
                        duration: 2,
                    })
                    return false
                }
            })
            .catch(() => {return false})
            .finally(() => {
                this.spinning = false;
            })
        },
        //初始化图纸
        fetchData(callback) {
            let params = {
                id:this.fileLists[this.activeKey].id,
                // id:'1523928915234459650',
                uuid:this.fileLists[this.activeKey].uuid,
                fileUrl:this.fileLists[this.activeKey].fileUrl,
                fileName:this.fileLists[this.activeKey].fileName,
                pageNo:this.pagination.pageNo,
                pageSize:this.pagination.pageSize
            }
            console.log('=========请求参数',params);
            // let res = [
            //     {
            //         "url": "https://doc.shoufusheji.com/img/operator-server/img/1642750173691.png",
            //         "height": 2418,
            //         "width": 3404
            //     },
            //     {
            //         "url": "https://doc.shoufusheji.com/img/operator-server/img/1642750173691.png",
            //         "height": 2418,
            //         "width": 3404
            //     }
            // ]
            // callback(res)
            // return
            this.spinning = true;
            getPdfs(params).then(res => {
                if(res.success) {
                    if(res.result.pdfImages) {
                        this.spinning = false;
                        callback(res.result)
                    }
                }else {
                    this.$notification.error({
                        message: '错误提示',
                        description: res.message,
                        duration: 3,
                    });
                    this.pagination.pageNo -=1;
                }
            })
            // .finally(() => {
            //     this.spinning = false;
            // })
        },
        //触底操作
        handleInfiniteOnLoad() {
            console.log('=========触底;')
            const data = this.contacts;
            // if(data.length==0) {
                // this.$notification.error({
                //     message: '错误提示',
                //     description: '无法获取PDF信息,请重新发起流程!',
                //     duration: 0,
                // })
                // return
            // }
            if (data.length == this.total) {
                this.$message.warning('图纸已加载完成!');
                return;
            }
            console.log('========继续加载')
            this.pagination.pageNo +=1;
            this.fetchData(res => {
                let resp = !!res.pdfImages?res.pdfImages:[];
                this.contacts = data.concat(resp);
                
                if(this.oldSealConfig.length>0) {
                    this.initChapterConfig(this.oldSealConfig);
                }
                if(this.oldSignConfig.length>0) {
                    this.initSignConfig(this.oldSignConfig);
                }
            });
        },
        //选择的那个章
        selectDrag(ev,item) {
            console.log('选择章====',ev,item,Date.parse(new Date())/1000);
            console.log('选择章====',this.$refs);
            ev.dataTransfer.effectAllowed = "move";  //移动效果
            ev.dataTransfer.setData("text", '');  //附加数据，　没有这一项，firefox中无法移动
            this.diffXY.x = (ev.offsetX || ev.layerX) + this.$refs.file.offsetParent.offsetLeft + 30;//ev.clientX - ev.target.offsetLeft
            this.diffXY.y = (ev.offsetY || ev.layerY) + this.$refs.file.offsetParent.offsetTop;//ev.clientY - ev.target.offsetTop
            return true
        },
        selectDragEnd(ev,item) {
            console.log('选择章====end',ev,item,this.diffXY,this.$refs.file.scrollLeft,this.$refs.file.scrollTop);
            ev.preventDefault() || ev.stopPropagation();  //不取消，firefox中会触发网页跳转到查找setData中的内容
            // return
            let temp = {
                id:Date.parse(new Date())/1000,
                pic:item.directions,
                type:item.signName,
                w:ev.target.naturalWidth || item.width,
                h:ev.target.naturalHeight || item.height,
                // scollTop:this.$refs.file.scrollTop,//签章初始位置
                initX:ev.clientX + this.$refs.file.scrollLeft - this.diffXY.x,
                initY:ev.clientY + this.$refs.file.scrollTop - this.diffXY.y,
                diff:'chapter',
                name:item.signName
            }
            // console.log('======temp',temp)
            this.els.push(temp);
            setTimeout(() => {
                this.els.forEach(el => {
                    el.initY = el.initY + 0.001;
                })
            },300)
        },
        //选择的签字
        signSelectDrag(ev,item) {
            console.log('选择签字====',ev,item,Date.parse(new Date())/1000);
            console.log('选择签字====',this.$refs);
            ev.dataTransfer.effectAllowed = "move";  //移动效果
            ev.dataTransfer.setData("text", '');  //附加数据，　没有这一项，firefox中无法移动
            this.diffXY.x = (ev.offsetX || ev.layerX) + this.$refs.file.offsetParent.offsetLeft + 30;//ev.clientX - ev.target.offsetLeft
            this.diffXY.y = (ev.offsetY || ev.layerY) + this.$refs.file.offsetParent.offsetTop;//ev.clientY - ev.target.offsetTop
            return true
            
        },
        signSelectDragEnd(ev,item) {
            console.log('选择签字====end',ev,item,this.diffXY,this.$refs.file.scrollLeft,this.$refs.file.scrollTop);
            ev.preventDefault() || ev.stopPropagation(); 
            let temp = {
                id:Date.parse(new Date())/1000,
                pic:item.signUrl,
                type:item.id,
                w:60,
                h:34,
                // scollTop:this.$refs.file.scrollTop,//签章初始位置
                initX:ev.clientX + this.$refs.file.scrollLeft - this.diffXY.x,
                initY:ev.clientY + this.$refs.file.scrollTop - this.diffXY.y,
                diff:'sign',
                name:item.name
            }
            this.els.push(temp);
            setTimeout(() => {
                this.els.forEach(el => {
                    el.initY = el.initY + 0.001;
                })
            },300)
        },
        //开始--close关闭操作
        onClicked(ev,item) {
            console.log('==========开始拖动',ev,item,this.els)
            // return
            if(ev.srcElement && (ev.srcElement.nodeName == 'svg' || ev.srcElement.nodeName =='path')) {
                let temp = this.els.filter(el => el.id != item.id);
                // console.log('========temp',temp);
                this.els = temp;
            }
        },
        //结束
        onDragstop(newRect,item) {
            console.log('=======结束',newRect,item);
            //其他章的页数
            let num = (newRect.top+item.h/2) <= this.imgWH.h ? 1 : Math.ceil(newRect.top/(this.imgWH.h+30));
            this.els.forEach(el => {
                if(el.id == item.id) {
                    el.left = newRect.left;
                    el.top = newRect.top;
                    el.pageNum = num;
                }
            })
            console.log('屏幕滚动的高度====计算页数',this.imgWH.h,num);
        },
        //点击盖章
        handlerSave() {
            // console.log('======签字未加载',this.oldSignConfig)
            // console.log('======章未加载',this.oldSealConfig)
            let oldSeal = this.oldSealConfig.filter(el =>el.selFlag!='yes')
            let oldSign = this.oldSignConfig.filter(el =>el.selFlag!='yes')
            this.sealLen = this.els.filter(el =>el.diff=='chapter').length + oldSeal.length;
            this.signLen = this.els.filter(el =>el.diff=='sign').length + oldSign.length;
            console.log('========',this.sealLen,this.signLen)
        },
        //保存盖章
        confirmSave() {
            this.signStr = this.checkedList.join(',');
            let oldSeal = this.oldSealConfig.filter(el =>el.selFlag!='yes')
            let oldSign = this.oldSignConfig.filter(el =>el.selFlag!='yes')
            this.seal(oldSeal,oldSign);
        },
        //盖章
        seal(oldSeal,oldSign) {
            console.log('========参数处理前',this.els)
            // return
            let tempArr = [];
            let signArr = [];
            this.els.forEach(el => {
                //章的原点是左上角
                let coordinateYTop = el.top - (this.imgWH.h+30)*(el.pageNum - 1) + el.h/2 -30;
                //签字人的原点是左下角
                let coordinateYBot = this.imgWH.h - (el.top - (this.imgWH.h+30)*(el.pageNum - 1)) - el.h/2 +30

                
                let temp = {
                    id: el.type,
                    signName:el.type,
                    page:el.pageNum,
                    y: 0,
                    x:el.left + el.w/2
                };
                if(el.diff =='sign') {
                console.log('========签字人',coordinateYBot)
                    temp.y = coordinateYBot - 17;
                    temp.x = temp.x - 30;
                    delete temp.signName;
                    signArr.push(temp)
                }else{
                    console.log('========章',coordinateYTop)
                    temp.y = coordinateYTop;
                    delete temp.id;
                    tempArr.push(temp)
                }
                
            })
            tempArr = tempArr.concat(oldSeal);
            signArr = signArr.concat(oldSign);
    
            let params =[
              {
                fileUrl:this.fileLists[this.activeKey].fileUrl,
                id:this.contractId,
                uuid:this.fileLists[this.activeKey].uuid,
                // id:'1523928915234459650',
                mode:'manual',
                tmpFile:this.totalObj.tmpFile,
                manualSealParam:{
                    dragSignList:signArr,
                    dragSealList:tempArr,
                    signPages:this.signStr
                }
            }
            ] 
            console.log('=====参数处理后',params);
            // return
            // if(params.manualSealParam.dragSignList.length==0&&params.manualSealParam.dragSignList.length==0){
            //  this.$message.error('没有要保存的签章数据!')
            //  return
            // }
            savePdfSignature(params).then(res => {
                if(res.success) {
                    this.$notification.success({
                        message: '成功提示',
                        description: res.message + '若无其他操作,请关闭当前页面！',
                        duration: 0,
                    });
                    // this.disabled = true;
                }else{
                    this.$notification.error({
                        message: '错误提示',
                        description: res.message,
                        duration: 2,
                    })
                }
            })
        },
        onChange(checkedList) {
            this.indeterminate = !!checkedList.length && checkedList.length < this.plainOptions.length;
            this.checkAll = checkedList.length === this.plainOptions.length;
        },
        onCheckAllChange(e) {
            console.log('=======e',e.target.checked)
            this.checkedList =  e.target.checked ? this.plainOptions : [];
            this.indeterminate = false;
            this.checkAll = e.target.checked;
            // Object.assign(this, {
            //     checkedList: e.target.checked ? this.plainOptions : [],
            //     indeterminate: false,
            //     checkAll: e.target.checked,
            // });
        },
              //获取图纸总页数
        getTotalPage(data) {
            // this.spinning = true;
            // return
            getPdfTotal({fileUrl:data.fileUrl}).then(res => {
                if(res.success) {
                    // this.visible = true;
                    this.totalObj.tmpFile = res.result.tmpFile;
                    this.totalObj.total = res.result.count ? res.result.count : 0;
                }else {
                    this.$notification.error({
                        message: '错误提示',
                        description: res.message,
                        duration: 0,
                    });
                    // this.visible = false;
                }
            }).finally(() => {
                // this.spinning = false;
            })
        },
    }

}
</script>

<style scoped lang="less">
/deep/.vdr.active:before{outline:1px dashed red !important;}
.seal_heard{
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #002b45;
    color: #fff;
    font-size: 14px;
    .back {margin-left:20px}
    .title{flex:1;text-align: center;}
    .btn{margin-right: 30px;}
}
.main {
    display: flex;
    height: calc(100vh - 80px);
    .left{
        width: 15%;
        background-color: #f6f6f6;
        padding-left: 20px;
        box-sizing: border-box;
        border-right: 1px solid #ebebeb;
        .sign {
            border-bottom: 1px solid #ebebeb;
            margin-top: 10px;
            
            height: calc(100vh - 90px);
            overflow: auto;
            &::-webkit-scrollbar{
                width: 8px;
                height: 8px;
                background-color: #F5F5F5;
            }
            &::-webkit-scrollbar-thumb{background-color: #ddd;}
            h4{height: 30px;line-height: 30px;}
            .sign_opt {
                .sign_opt_item {
                    display: flex;
                    justify-content: center;
                    padding: 10px 0;
                    // height: 100px;
                    position: relative;
                    cursor: pointer;
                    span{height: 30px;line-height: 30px;color: #000;}
                    // i{background-color: #fcdf89;}
                } 
            }
        }
    }
    .right{
        flex:1;
        position: relative;
        // overflow: auto;
        overflow-y: hidden;
        h3{position: absolute;
        width: 100%;
        top: 0; 
        margin: 0; 
        height: 30px;
        line-height: 30px;
        text-align: center;
        border-bottom: 1px solid #ebebeb;background-color: #f8f8f8;
        font-size: 14px;z-index: 99;
        
        }
        .right_box {
            display: flex;
            // margin-top: 30px;
            .file_right,.file_left {
                width: 30px;
                height: calc(100vh - 80px);
                background-color: #f8f8f8;
                padding: 0;
                margin: 0;
            }
            .file_left{box-shadow:  8px 0px 8px rgba(235,235,235,1);}
            .file_right{
                width: 90px;
                height: calc(100vh - 110px);
                margin-top: 30px;
                box-shadow:  -8px 0px 8px rgba(235,235,235,1);
                /deep/.ant-anchor-wrapper {
                    overflow: auto;
                    max-height: calc(100vh - 150px) !important;
                    margin-left:0;
                    // padding-bottom: 100px;
                    &::-webkit-scrollbar{
                        width: 8px;
                        height: 8px;
                        background-color: #F5F5F5;
                    }
                    &::-webkit-scrollbar-thumb{background-color: #ddd;}
                }
                >button{margin:10px 0;}
            }
            .file {
                flex: 1;
                overflow: auto;
                &::-webkit-scrollbar{
                    width: 8px;
                    height: 8px;
                    background-color: #F5F5F5;
                }
                &::-webkit-scrollbar-thumb{background-color: #ddd;}
                // height: calc(100vh - 80px);
                height: calc(100vh - 120px);
                .file_box{
                    position: relative;
                    margin: auto;
                    border: 1px solid #ddd;
                    .pdf_list{margin-top:30px; div{ &::-webkit-scrollbar{
                    width: 8px;
                    height: 8px;
                    background-color: #F5F5F5;
                }
                &::-webkit-scrollbar-thumb{background-color: #ddd;}} }

                    /deep/.ant-list-item{flex-direction: column;padding: 0;}
                }
                .page{height: 30px;line-height: 30px;margin: 0;padding: 0;text-align: center;background-color:#f8f8f8;}//bisque
                .moveBox{z-index: 999;position: absolute;/deep/.content-container{overflow: hidden;}}
                .moveBox:hover{
                    cursor: pointer;
                    .icon_close{opacity: 1;}
                }
                .contant{position: relative;}
                .icon_close{position: absolute;font-size: 24px;right: 0;z-index: 9999;opacity: 0;}
            }
        }
        // img{width: 100%;}
        
    }
}
.file_name {
    height: 40px;
    background-color: #f8f8f8;
    /deep/.ant-tabs-bar{margin: 0 !important;}
}
/deep/.ant-spin-dot-item,.ant-spin{color:#002b45}
</style>