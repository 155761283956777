<template>
    <a-spin tip="Loading..." :spinning="spinning" class="template">
        <header class="seal_heard">
            <span class="title">合同预盖章</span>
            <div class="btn" v-if="editSeal">
                <a-tooltip placement="bottomRight">
                    <template slot="title">
                        <span>手动盖章：需要自己选择拖动盖法人章、公章、骑缝章！</span>
                    </template>
                    <a-button type="primary"  
                        :loading="loading" 
                        @click="handlerAppointSeal">去盖章</a-button>
                </a-tooltip>
            </div>
        </header>
        <div class="main"
            v-infinite-scroll="onLoadMore"
            :infinite-scroll-disabled="busy"
            :infinite-scroll-immediate="false"
            :infinite-scroll-distance="0">
            <p style="color:red;text-align:center;margin:10px 0;">
                注：此处合同盖章皆为合同预盖章，一切以合同真实盖章为准。
                真实合同盖章是合同评审通过后，根据预盖章进行盖章。流程结束后可在合同详情页下载预览！
            </p>
            <!-- 金慧上传过pdf -->
            <!-- 已盖章 -->
            <div class="contract_main_sub" v-if="checkObj.exist">
                <span v-if="checkObj.sign">该合同已盖章，请阅览预盖章情况。</span>
                <span v-else>该合同未盖章。去盖章！</span>
                <a-popconfirm v-if="!checkObj.sign && !checkObj.attId"
                    placement="rightTop" ok-text="直接盖章" cancel-text="取消" @confirm="directSeal">
                    <template slot="title">
                        <p style="color:red;padding:0 10px;fontSize:18px;">该合同已上传过PDF可直接盖章！</p>
                    </template>
                    <a-button type="primary" ghost size="small">去盖章</a-button>
                </a-popconfirm>
                <a-popconfirm v-if="!checkObj.sign && checkObj.attId" :disabled="editSeal"
                    placement="rightTop" ok-text="直接盖章" cancel-text="修改&盖章" @confirm="directSeal" @cancel="editAndSeal">
                    <template slot="title">
                        <p style="color:red;padding:0 10px;fontSize:18px;">预览后去盖章！</p>
                        <p v-if="checkObj.contract">该合同已上传过PDF可直接盖章，也可<span style="color:orange;">在线修改</span>后去盖章！</p>
                    </template>
                    <a-button type="primary" ghost size="small" :disabled="editSeal">去盖章</a-button>
                </a-popconfirm>
                <!-- 合同的pdf图片 -->
                <div class="png_preview" v-if="!editSeal && pngList && pngList.length">
                    <a-list :data-source="pngList" item-layout="vertical" :split="false">
                        <a-list-item slot="renderItem" slot-scope="item, index">
                            <img :src="item.url" alt="合同附件">
                            <h4 class="page" >第{{index+1}}页</h4>
                        </a-list-item>
                    </a-list>
                </div>

                <!-- <ul >
                    <li v-for="(url,index) in pngList" :key="url.url">
                        <img :src="url.url" alt="合同附件">
                        <h4 class="page" >第{{index+1}}页</h4>
                    </li>
                    
                    <div v-if="pngList.length>0  && pngList.length < pagination.total"
                        :style="{ textAlign: 'center', marginTop: '12px', height: '32px', lineHeight: '32px' }"
                        >
                        <a-button @click="onLoadMore">
                            更多加载
                        </a-button>
                    </div>
                </ul> -->
            </div>
            <!-- 2.未盖章--打开onlyoffice -->
            <onlyoffice :records="records" v-if="editSeal"></onlyoffice>
            
        </div>

        
        <footer class="foot">Copyright © 2021 首辅工程设计有限公司 蜀ICP备2021009126号</footer>
    </a-spin>
</template>

<script>
import onlyoffice from '@/components/onlyoffice/onlyoffice'
import infiniteScroll from 'vue-infinite-scroll';
import {
    // getContactTemplates,
    isGenerateContact,
    generateContactTemplate,
    transformContactToPdf,
    getContractPdf,
    // automaticSeal
} from '@/api/api'

import {mapMutations} from 'vuex';
export default {
    // name:'合约模板',
    components:{
        onlyoffice
    },
    directives: { infiniteScroll },
    data() {
        return {
            spinning:false,
            loading:false,
            checkObj:{},
            pngList:[],//pdf图片
            editSeal:false,
            //onlyoffice参数
            records:{},
            //分页查询
            pagination:{
                pageNo:1,
                pageSize:5,
                total:0
            },
            busy:false
        }
    },
    mounted() {
        // this.initData();
    },
    computed:{
        reQuery() {
            return this.$route.query
        },
        contract_id() {
            return this.reQuery.id|| 'ae6a0116-73df-42c5-a41d-b3b6bb89632c';
        },
        source() {
            return this.reQuery.source || '1'
        }
    },
    watch:{
        'contract_id':{
            handler(val) {
                console.log('========contract_id',val);
                if(val) {
                    // let obj = {
                    //     attId:'1476359619132858370',
                    //     contract:'https://doc.shoufusheji.com/img/temp/1640826537270.doc'
                    // }
                    // this.openOnlyoffice(obj)
                    // return
                    this.editSeal = false;
                    this.checkGenerateContact(val);
                }
            },
            immediate:true
        }
    },
    methods:{
        //验证是否生成过合同
        checkGenerateContact(val) {
            this.spinning = true;
            isGenerateContact({
                contractId:val,
                source:this.source,
                pageSize:this.pagination.pageSize,
                pageNo:this.pagination.pageNo
            }).then(res => {
                console.log('======返回值',res)
                if(res.success) {
                    this.spinning = false;
                    let resp = res.result;
                    this.checkObj = resp;
                    this.pngList = this.pngList.concat(resp.png);
                    this.pagination.total = resp.totalSize;
                    // if(resp.exist && !resp.sign) {
                    //     this.editSeal = !!resp.attId
                    // }
                }else {
                    this.$notification.error({
                        message: '错误提示',
                        description: res.message,
                        duration: 0,
                    })
                    this.pagination.pageNo -=1;
                }
            })
        },
        //加载更多
        onLoadMore() {
            const data = this.pngList;
            console.log('======加载进入',data.length)
            if(data.length && data.length >= this.pagination.total) {
                this.$message.warning('加载完成');
                this.busy = true;
                return
            }
            if(data.length) {
                this.pagination.pageNo +=1;
                this.checkGenerateContact(this.contract_id)
            }
        },
        //附件类型
        ifFileType(text) {
            console.log(`Found match : text`, text);
            const regex = /(\.doc)$|(\.docx)$|(\.xls)$|(\.xlsx)$|(\.ppt)$|(\.pptx)$|(\.pdf)$/gm;
            // const str = `http://.doc.shoufusheji.com:8087/IndexServlet/track?fileName=%E6%A3%80%E6%B5%8B%E7%B3%BB%E7%BB%9F-%E9%9C%80%E6%B1%82%E6%96%87%E6%A1%A3.doc`;
            let m;
    
            if ((m = regex.exec(text)) !== null) {
                console.log(`Found match, group : ${m[0]}`, m.index);
                return m[0]
            }
            return null
        },
        isSelCol(id) {
            this.templateList.forEach(el => {
                if(id === el.id) {
                    el.selFlag = true;
                }else {
                    el.selFlag = false;
                }
            });
            this.templateList = [...this.templateList]
        },
        //预盖章--编辑&查看后盖章
        handlerAppointSeal() {
            this.spinning = true;
            // this.transformContact();
            this.handlerSeal()
        },
        //转换word-to-pdf
        // transformContact() {
        //     transformContactToPdf({
        //         contractId:this.contract_id,
        //         source:this.source
        //     }).then(res => {
        //         this.handlerSeal(res)
        //     })
        // },
        handlerSeal() {
            this.spinning = false;
            // if(res.success) {
                // let pdfs = str?res.result.images:res.result
                //选择手动盖章操作---跳转到盖章页面
                this.$router.push({
                    path: '/businessPlatform/seal',
                    query: {
                        // pdfs:JSON.stringify(pdfs),
                        contractId:this.contract_id,
                        source:this.source
                    }
                });
                this.Set_disabled(false);
            // }else {
            //     this.$notification.error({
            //         message: '错误提示',
            //         description: res.message,
            //         duration: 0,
            //     })
            // }
        },
        //pdf直接盖章
        directSeal() {
            this.editSeal = false;
            // this.getInitPdf();
            this.handlerSeal();
        },
        //获取最新pdf
        // getInitPdf() {
        //     this.spinning = true;
        //     getContractPdf({
        //         contractId:this.contract_id,
        //         source:this.source
        //     }).then(res => {
        //         this.handlerSeal(res,'preview');
        //         this.spinning = false;
        //     })
        // },
        //编辑&盖章
        editAndSeal() {
            this.editSeal = true;
            this.openOnlyoffice(this.checkObj,'handleEdit');

        },
        //打开onlyoffice
        openOnlyoffice(record) {
            let fileType = this.ifFileType(record.contract)
            console.log('========fileType',record,fileType)
            if (!fileType) return;
            let records = {
                attId: record.attId,
                createBy: "",
                createTime: "",
                equipmentId: "",
                fileName: record.name + fileType,
                fileType: fileType.replace('.', ''),
                isType:"handleEdit",
                id: "",
                title: "",
                url: record.contract,
                server:record.server
            };
            this.records = records;
        },
        ...mapMutations(['Set_disabled'])
    }
}
</script>

<style lang="less" scoped>
.seal_heard{
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #002b45;
    color: #fff;
    font-size: 14px;
    .title{flex:1;text-align: center;}
    .btn{margin-right: 20px;button{margin-left: 10px;}}
}
.main {
    height: calc(100vh - 90px);
    overflow-y: scroll;
    &::-webkit-scrollbar{
        width: 6px;
        background-color: #F5F5F5;
    }
    &::-webkit-scrollbar-thumb{background-color: #ddd;}
    .contract_main_sub {
        margin: 10px 0;
        text-align: center;
        >span{margin:10px;display: inline-block;font-size: 14px;color:#000;}
        .png_preview{
            /deep/.ant-list-item{padding: 0;}
        }
    }
}
.template{
    background-color: #f7f7f7;box-sizing: border-box;font-size: 12px;color:#999;
}
.foot {
    width: 100%;
    height: 40px;
    line-height: 40px;
    background-color: #f6f6f6;
    border-top: 1px solid #ebebeb;
    text-align: center;
    position: fixed;
    z-index: 99;
}
.page{height: 30px;line-height: 30px;margin: 0;padding: 0;text-align: center;background-color:#f8f8f8;}

</style>