import { render, staticRenderFns } from "./TurningThePage.vue?vue&type=template&id=4c12a630&scoped=true&"
import script from "./TurningThePage.vue?vue&type=script&lang=js&"
export * from "./TurningThePage.vue?vue&type=script&lang=js&"
import style0 from "./TurningThePage.vue?vue&type=style&index=0&id=4c12a630&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c12a630",
  null
  
)

export default component.exports