<template>
    <a-spin tip="Loading..." :spinning="spinning" class="template">
        <header class="seal_heard">
            <span class="title">{{ title }}附件预览</span>
            <div class="btn">
                <a-tooltip placement="bottomRight" v-if="!checkSign">
                    <template slot="title">
                        <span>直接盖章:点击进入盖章页面,拖动选择需要的章!</span>
                    </template>
                    <a-button type="primary" @click="handlerAppointSeal">去盖章</a-button>
                </a-tooltip>
            </div>
        </header>
        <div style="background-color: #fff;" v-if="fileData.sign" class="sealbox">
            <div>用印关键信息</div>
            <a-button type="primary" @click="canncelqrcode" v-if="fileData.sign && !!fileData.checkQrCodeState"
                :disabled="isallqr">一键取消二维码</a-button>
        </div>
        <div style="background-color: #fff;padding-top: 16px;">
            <div class="main" v-infinite-scroll="onLoadMore" :infinite-scroll-disabled="busy"
                :infinite-scroll-immediate="false" :infinite-scroll-distance="0" style="display: flex;">
                <div style="width: 25%;background-color: #fff;height: 100%;padding: 16px;color: black;font-size: 14px;"
                    v-if="fileData.sign">
                    <div style=" border: 1px solid #ebebeb;padding: 16px;">
                        <div style="margin-bottom: 10px;">
                            用印类型:&nbsp;&nbsp;{{ info.applyType == 0 ? '其他用印' : '项目资料用印' }}
                        </div>
                        <div v-if="info.applyType == 1" style="margin-bottom: 10px;">
                            项目名称:&nbsp;&nbsp;{{ info.projectName }}
                        </div>
                        <div style="margin-bottom: 10px;">
                            所属公司:&nbsp;&nbsp;{{ info.platformCompanyName }}
                        </div>
                        <div style="margin-bottom: 10px;">
                            文件类型:&nbsp;&nbsp;{{ info.fileType }}
                        </div>
                        <div style="margin-bottom: 10px;">
                            申请理由:&nbsp;&nbsp;{{ info.applyReason }}
                        </div>
                        <div style="margin-bottom: 10px;">
                            收件人: &nbsp;&nbsp;&ensp;{{ info.addresseeInfo }}
                        </div>
                        <div>
                            申请印章:
                        </div>
                        <div style="display: flex;">
                            <div v-for="(item, index) in info.oaSealApplyDetailList" :key="index" style="margin: 0 10px;">{{
                                item.sealName }}</div>

                        </div>
                    </div>
                </div>
                <div style="overflow:auto; border: 1px solid #ebebeb;" :style="fileData.sign ? 'width: 75%' : 'width: 100%'">
                    <a-tabs v-model="activeKey" size="small" @change="tabChange">
                        <a-tab-pane v-for="(name, index) in fileLists" :key="index">
                            <span slot="tab" :title="name">{{ name.slice(0, 6) + '...' }}</span>
                            <div class="contract_main_sub" v-if="pngList && pngList.length">
                                <span>当前为：{{ name }} 文件预览</span>
                                <span v-if="fileData.sign" style="color:blue;">该文件已预盖章，请阅览预盖章情况。</span>
                                <span v-if="!fileData.sign" style="color:red;">该文件未盖章,可以去盖章</span>
                                <p v-if="fileData.sign && !!fileData.checkQrCodeState">
                                    <span style="color:red;">该文件是否贴二维码：</span>
                                    <a-radio-group :options="codeOptions" v-model="fileData.checkQrCodeState"
                                        @change="codeChange" />
                                </p>

                                <!-- 合同的pdf图片 -->
                                <ul style="overflow:auto;">


                                    <li v-for="(url, index) in pngList" :key="url.url">
                                        <img :src="url.url" alt="文件附件" style="border: 1px solid #ddd;">
                                        <h4 class="page">第{{ index + 1 }}页</h4>
                                    </li>
                                </ul>
                            </div>
                        </a-tab-pane>
                    </a-tabs>
                </div>

                <!-- <div v-if="pngList.length>0  && pngList.length < pagination.total"
                :style="{ textAlign: 'center', marginTop: '12px', height: '32px', lineHeight: '32px' }"
                >
                <a-spin v-if="loadingMore" />
                <a-button v-else @click="onLoadMore">
                    更多加载
                </a-button>
            </div> -->
            </div>
        </div>

        <footer class="foot">Copyright © 2021 首辅工程设计有限公司 蜀ICP备2021009126号</footer>
    </a-spin>
</template>

<script>
import {
    getFileLists,
    getfiledetail,
    getPngByFileName,
    saveQrCodeConfig,
    getfileQrCodeState
} from '@/api/api'
import infiniteScroll from 'vue-infinite-scroll';
// import {mapMutations} from 'vuex';
const codeOptions = [
    {
        label: '是',
        value: 1,
    },
    {
        label: '否',
        value: 2,
    }
]
export default {
    name: 'moreaccessories',
    data() {
        return {
            isallqr: false,
            spinning: false,
            activeKey: 0,
            fileLists: [],//附件列表
            fileData: {},//对应附件表的数据
            pngList: [],//对应附件表的png图片
            checkSign: false,//该流程是否结束==结束后不重复盖章
            chapters: [],//签章lists
            title: '',//标题
            //分页查询
            pagination: {
                pageNo: 1,
                pageSize: 20,
                total: 0
            },
            info: {},
            //加载更多
            busy: false,
            //是否盖二维码
            codeOptions,
        }
    },
    directives: { infiniteScroll },
    mounted() {
    },
    computed: {
        reQuery() {
            return this.$route.query
        },
        contract_id() {
            return this.reQuery.id //|| 'aeae0134-c269-49bb-b4b9-a89b42333fdc';//ae6800a2-63eb-47c8-934b-042d37646478
        }
    },
    watch: {
        'contract_id': {
            handler(val) {
                if (val) {
                    this.getFiles(val);
                }
            },
            immediate: true
        },
        'fileLists': {
            handler(list) {
                console.log(list, 'list');
                if (list.length) {
                    this.getSelFilePng(list[this.activeKey], this.contract_id);
                }
            },
            immediate: true
        }
    },
    methods: {
        canncelqrcode() {
            this.fileData.checkQrCodeState = 2
            saveQrCodeConfig({
                id: this.fileData.applyId,
                isAll: 1,
                qrState: 2
            }).then(res => {
                if (res.success) {
                    this.$notification.success({
                        message: '成功提示',
                        description: '设置成功',
                        duration: 1,
                    })
                    this.isallqr = true
                } else {
                    this.$notification.error({
                        message: '错误提示',
                        description: res.message,
                        duration: 1,
                    })
                }
            })
        },
        codeChange(ev) {
            console.log('======val', ev.target.value)
            // return
            saveQrCodeConfig({
                id: this.fileData.applyId,
                qrState: ev.target.value
            }).then(res => {
                if (res.success) {
                    this.$notification.success({
                        message: '成功提示',
                        description: '设置成功',
                        duration: 1,
                    })
                    getfileQrCodeState({id:this.contract_id}).then(res1 => {
                        if (res1.success) {
                            this.isallqr = res1.result
                        } else {
                            this.$notification.error({
                                message: '错误提示',
                                description: res1.message,
                                duration: 1,
                            })
                        }
                    })
                } else {
                    this.$notification.error({
                        message: '错误提示',
                        description: res.message,
                        duration: 1,
                    })
                }
            })
        },
        //获取文件列表
        getFiles(contractId) {
            this.spinning = true;

            getFileLists({
                id: contractId
            }).then(res => {
                if (res.success) {

                    getfiledetail({ id: contractId }).then(res1 => {
                        if (res1.success) {
                            this.spinning = false;
                            this.info = res1.result

                            console.log(res1);
                        } else {
                            this.$notification.error({
                                message: '错误提示',
                                description: res1.message,
                                duration: 0,
                            })
                        }
                    })
                    let resp = res.result;
                    this.title = resp.title;//标题
                    this.fileLists = resp.fileNames;//附件名称列表
                    this.checkSign = resp.checkSign;//印章流程是否结束
                    let electron = resp.sealSealInfos;//电子章
                    let cross = resp.perforationSealInfos;//骑缝章
                    getfileQrCodeState({id:this.contract_id}).then(res1 => {
                        if (res1.success) {
                            this.isallqr = res1.result
                        } else {
                            this.$notification.error({
                                message: '错误提示',
                                description: res1.message,
                                duration: 1,
                            })
                        }
                    })
                    electron.forEach(el => {
                        el.category = 0
                    });
                    cross.forEach(el => {
                        el.category = 1
                    })
                    this.chapters = cross.concat(electron);

                } else {
                    this.$notification.error({
                        message: '错误提示',
                        description: res.message,
                        duration: 0,
                    })
                }
            })
        },
        //选取单个附件
        tabChange(activeKey) {
            console.log('========选择的tab', activeKey)
            this.pagination.pageSize = 20;
            this.pagination.pageNo = 1
            this.pagination.total = 0;
            this.pngList = []
            this.getSelFilePng(this.fileLists[activeKey], this.contract_id);
        },
        //获取附件详情png
        getSelFilePng(filename, contractId) {
            this.spinning = true
            getPngByFileName(
                {
                    filename: filename,
                    id: contractId,
                    type: 1,
                    pageSize: this.pagination.pageSize,
                    pageNo: this.pagination.pageNo
                }
            ).then(res => {
                if (res.success) {
                    this.fileData = res.result;
                    this.pngList = this.pngList.concat(res.result.png)
                    this.pagination.total = res.result.totalSize
                } else {
                    this.$notification.error({
                        message: '错误提示',
                        description: res.message,
                        duration: 0,
                    })
                }
            }).finally(() => {
                this.spinning = false
            })
        },
        //加载更多
        onLoadMore() {
            console.log('=======加载耕读', this.fileLists[this.activeKey])
            const data = this.pngList;
            console.log('======加载进入', data.length)
            if (data.length && data.length >= this.pagination.total) {
                this.$message.warning('加载完成');
                this.busy = true;
                return
            }
            if (this.fileLists[this.activeKey]) {
                this.pagination.pageNo += 1;
                this.getSelFilePng(this.fileLists[this.activeKey], this.contract_id);
            }
        },
        //去盖章
        handlerAppointSeal() {
            this.$router.push({
                path: '/businessPlatform/multiattachmentseal',
                query: {
                    chapters: JSON.stringify(this.chapters),//章大全
                    files: JSON.stringify(this.fileLists),//附件名称列表
                    contractId: this.contract_id,
                    title: this.title
                }
            });
            // this.Set_disabled(false);
        },
        // ...mapMutations(['Set_disabled'])
    }
}
</script>

<style lang="less" scoped>
.seal_heard {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #002b45;
    color: #fff;
    font-size: 14px;

    .title {
        text-align: center;
        flex: 1
    }

    .btn {
        margin-right: 20px;

        button {
            margin-left: 10px;
        }
    }
}

.main {
    // padding: 20px;
    height: calc(100vh - 140px);
    overflow-y: scroll;
    background-color: #F5F5F5;

    &::-webkit-scrollbar {
        width: 6px;
        background-color: #F5F5F5;

    }

    &::-webkit-scrollbar-thumb {
        background-color: #ddd;
    }

    .contract_main_sub {
        margin: 10px 0;
        text-align: center;
        position: relative;

        >span {
            margin: 10px;
            display: inline-block;
            font-size: 14px;
            color: #000;
        }
    }
}

.template {
    background-color: #f7f7f7;
    box-sizing: border-box;
    font-size: 12px;
    color: #999;
}

.foot {
    width: 100%;
    height: 40px;
    line-height: 40px;
    background-color: #f6f6f6;
    border-top: 1px solid #ebebeb;
    text-align: center;
    position: fixed;
    z-index: 99;
}

.page {
    width: 100%;
    height: 30px;
    line-height: 30px;
    margin: 0;
    padding: 0;
    text-align: center;
    background-color: #f8f8f8;
}

.sealbox {
    background-color: #fff;
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    color: #000;
    align-items: center;
    border-bottom: 1px solid #ebebeb;
}</style>