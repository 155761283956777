<template>
    <a-spin :spinning="confirmLoading">
        <div class="box">
            <h3 class="title">
                <span>排查报告</span>
                <img :src="require('@/assets/logo.png')" alt="首辅" srcset="" 
                style="width:60px;height:30px;margin-right:10px;">
            </h3>
            <div class="content">
                <van-cell title="项目名称" value="绵阳市涪城区危房排查整治工作" ></van-cell>
                <van-cell title="排查单位" value="首辅工程设计有限公司绵阳分公司"></van-cell>
                <van-cell title="地    址" value="绵阳市经开区绵州大道中段199号万达广场20栋417号"></van-cell>
                <van-cell title="  联系人" value="李军：15682200888"></van-cell>
                <van-cell title="  联系人" value="何成贵：13398377652"></van-cell>
            </div>
        </div>
    </a-spin>
</template>

<script>
import {
    
} from '@/api/api'
import { 
    Cell 
} from 'vant';

export default {
    name:'pcReport',
    components:{
        [Cell.name]:Cell,
    },
    data() {
        return {
            confirmLoading:false
        }
    }
}
</script>

<style lang="less" scoped>
.title{
    text-align: center;
    padding: 5px 0;
    background-color:#002b45 ;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    >span{flex:1;text-align: center;transform: translateX(34px);}
}
/deep/.van-cell__value{flex:2;text-align: left;}
.content {height: calc(100vh - 95px);overflow-y: auto;}
</style>