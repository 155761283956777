<template>
    <a-spin tip="Loading..." :spinning="spinning" class="template">
        <header class="seal_heard">
            <span class="title">{{title}}附件预览</span>
            <div class="btn" v-if="!checkSign">
                <a-tooltip placement="bottomRight">
                    <template slot="title">
                        <span>直接盖章:点击进入盖章页面,拖动选择需要的章!</span>
                    </template>
                    <a-button type="primary" @click="handlerAppointSeal">去盖章</a-button>
                </a-tooltip>
            </div>
        </header>
        <div class="main"
            v-infinite-scroll="onLoadMore"
            :infinite-scroll-disabled="busy"
            :infinite-scroll-immediate="false"
            :infinite-scroll-distance="0">
            <a-tabs v-model="activeKey" size="small" @change="tabChange">
                <a-tab-pane v-for="(name,index) in fileLists" :key="index">
                    <span slot="tab" :title="name">{{name.slice(0,6)+'...'}}</span>
                    <div class="contract_main_sub" v-if="pngList && pngList.length">
                        <span>当前附件为：{{name}} 文件预览</span>
                        <div v-if="fileData.sign" style="color:blue;">
                            该文件已预盖章，请阅览预盖章情况。
                        </div>
                        <div v-if="!fileData.sign" style="color:red;margin-bottom:10px;">
                            该文件未盖章，可以去盖章；<span v-if="activeKey==0 && !!fileData.attId">也可修改主合同并盖章！</span>
                            <a-popconfirm v-if="activeKey==0 && !!fileData.attId" :disabled="flag"
                                placement="rightTop" ok-text="修改&盖章" cancel-text="取消" @confirm="editSeal">
                                <template slot="title">
                                    <p style="color:red;padding:0 10px;fontSize:18px;">预览后去盖章！</p>
                                    <p v-if="fileData.contract">该合同可<span style="color:orange;">在线修改</span>后去盖章！</p>
                                    <p>非必要情况,不要去修改</p>
                                </template>
                                <a-button type="primary" ghost size="small" :disabled="flag">修改主合同</a-button>
                            </a-popconfirm>
                        </div>
                        <!-- 合同的pdf图片 -->
                        <ul v-if="!flag" style="overflow:auto">
                            <li v-for="(url,index) in pngList" :key="url.url">
                                <img :src="url.url" alt="文件附件">
                                <h4 class="page" >第{{index+1}}页</h4>
                            </li>
                            <!-- <div v-if="pngList.length>0  && pngList.length < pagination.total"
                                :style="{ textAlign: 'center', marginTop: '12px', height: '32px', lineHeight: '32px' }"
                                >
                                <a-button @click="onLoadMore">
                                    更多加载
                                </a-button>
                            </div> -->

                        </ul>
                    </div>
                    <div v-else>
                        <a-empty />
                    </div>
                </a-tab-pane>
            </a-tabs>
            <onlyoffice :records="records" v-if="flag" style="height:80%;"></onlyoffice>
        </div>
        <footer class="foot">Copyright © 2021 首辅工程设计有限公司 蜀ICP备2021009126号</footer>
    </a-spin>
</template>

<script>
import {
    isGenerateContact,
    getConSuppleByFileName,
} from '@/api/api'
import onlyoffice from '@/components/onlyoffice/onlyoffice'
import {ifFileType}from '@/utils/util'
import infiniteScroll from 'vue-infinite-scroll';
// import {mapMutations} from 'vuex';
export default {
    name:'moreaccessories',
    components:{onlyoffice},
    data() {
        return {
            spinning:false,
            activeKey:0,
            fileLists:[],//附件列表
            fileData:{},//对应附件表的png图片
            checkSign:false,//该流程是否结束==结束后不重复盖章
            chapters:[],//签章lists
            title:'',//标题
            //主合同数据
            mainFileData:{},
            //onlyoffice
            flag:false,
            records:{},
            //分页查询
            pagination:{
                pageNo:1,
                pageSize:5,
                total:0
            },
            pngList:[],//对应附件表的png图片
            busy:false

        }
    },
    directives: { infiniteScroll },
    mounted() {
    },
    computed:{
        reQuery() {
            return this.$route.query
        },
        contract_id() {
            return this.reQuery.id|| 'ae9400b6-bacf-465f-ade4-f5a54290ba29';
        },
        source() {
            return this.reQuery.source || '2'
        }
    },
    watch:{
        'contract_id':{
            handler(val) {
                if(val) {
                    this.getFiles(val);
                }
            },
            immediate:true
        },
        // 'fileLists':{
        //     handler(list) {
        //         if(list.length) {
        //             this.getSelFilePng(list[this.activeKey],this.contract_id);
        //         }
        //     },
        //     immediate:true
        // }
    },
    methods:{
        //获取主合同附件
        getFiles(contractId) {
            this.spinning = true;
            isGenerateContact({
                contractId:contractId,
                source:this.source,
                pageSize:this.pagination.pageSize,
                pageNo:this.pagination.pageNo
            }).then(res => {
                if(res.success) {
                    this.spinning = false;
                    let resp = res.result;
                    let attack = !!resp.contractAttachment?resp.contractAttachment:[];
                    this.title = resp.name+'及补充协议';//标题
                    this.fileData = resp;
                    //主合同数据--缓存一份
                    this.checkSign = resp.sign;//流程是否结束
                    this.mainFileData = resp;
                    //pdf
                    this.pagination.total = res.result.totalSize
                    this.pngList = this.pngList.concat(res.result.png);
                    if(resp.png.length) {
                        attack.unshift('主合同');
                    }else {
                        this.getSelFilePng(attack[this.activeKey],this.contract_id);
                    }
                    this.fileLists = attack;//附件名称列表
                    console.log('===========pngList',this.pngList)
                }else {
                    this.$notification.error({
                        message: '错误提示',
                        description: res.message,
                        duration: 0,
                    })
                    this.pagination.pageNo -=1;
                }
            })
        },
        //选取单个附件
        tabChange(activeKey){
            console.log('========选择的tab',activeKey)
            this.pagination.pageSize = 5;
            this.pagination.pageNo = 1
            this.pagination.total = 0;
            this.pngList = [];
            this.getSelFilePng(this.fileLists[activeKey],this.contract_id);
        },
        //获取附件详情png
        getSelFilePng(filename,contractId){
            this.flag = false;
            if(filename=='主合同') {
                // this.pngList = this.mainFileData.png;
                // this.fileData.sign = this.checkSign;
                // this.pagination.total = this.mainFileData.totalSize;
                this.getFiles(this.contract_id)
            }else {
                this.spinning=true
                getConSuppleByFileName(
                    {
                        fileName:encodeURIComponent(filename),
                        changeId:contractId,
                        type:1,
                        pageSize:this.pagination.pageSize,
                        pageNo:this.pagination.pageNo,
                        source:this.source
                    }
                ).then(res => {
                    if(res.success) {
                        this.fileData = res.result;
                        this.pngList = this.pngList.concat(res.result.png)
                        this.pagination.total = res.result.totalSize
                    }else {
                        this.$notification.error({
                            message: '错误提示',
                            description: res.message,
                            duration: 0,
                        })
                        this.pagination.pageNo -=1;
                    }
                }).finally(() => {
                    this.spinning=false
                })
            }
            
        },
        //加载更多
        onLoadMore() {
            console.log('=======加载耕读',this.fileLists[this.activeKey])
            const data = this.pngList;
            console.log('======加载进入',data.length)
            if(data.length && data.length >= this.pagination.total) {
                this.$message.warning('加载完成');
                this.busy = true;
                return
            }
            if(this.fileLists[this.activeKey]) {
                this.pagination.pageNo +=1;
                if(this.fileLists[this.activeKey] == '主合同') {
                    this.getFiles(this.contract_id)
                }else {
                    this.getSelFilePng(this.fileLists[this.activeKey],this.contract_id)
                }
            }
        },
        //去盖章
        handlerAppointSeal() {
            this.$router.push({
                path: '/businessPlatform/contactSupplementSeal',
                query: {
                    files:JSON.stringify(this.fileLists),//附件名称列表
                    contractId:this.contract_id,
                    title:this.title,
                    source:this.source
                }
            });
        },
        //修改主合同----打开onlyOffice
        editSeal() {
            this.flag = true;
            this.openOnlyoffice(this.mainFileData)
        },
        //打开在线编辑
        openOnlyoffice(record) {
            console.log('=======record',record)
            let fileType = ifFileType(record.contract)
            console.log('========fileType',record,fileType)
            if (!fileType) return;
            let records = {
                attId: record.attId,
                createBy: "",
                createTime: "",
                equipmentId: "",
                fileName: record.name + fileType,
                fileType: fileType.replace('.', ''),
                isType:"handleEdit",
                id: "",
                title: "",
                url: record.contract,
                server:record.server
            };
            this.records = records;
        },
        // ...mapMutations(['Set_disabled'])
    }
}
</script>

<style lang="less" scoped>
.seal_heard{
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #002b45;
    color: #fff;
    font-size: 14px;
    .title{flex:1;text-align: center;}
    .btn{margin-right: 20px;button{margin-left: 10px;}}
}
.main {
    // padding: 20px;
    height: calc(100vh - 90px);
    overflow-y: scroll;
    &::-webkit-scrollbar{
        width: 6px;
        background-color: #F5F5F5;
    }
    &::-webkit-scrollbar-thumb{background-color: #ddd;}
    .contract_main_sub {
        // margin: 10px 0;
        text-align: center;
        >span{margin:10px;display: inline-block;font-size: 14px;color:#000;}
        ul>li {
            margin-bottom: 10px;
        }
    }
}
.template{
    background-color: #f7f7f7;box-sizing: border-box;font-size: 12px;color:#999;
}
.foot {
    width: 100%;
    height: 40px;
    line-height: 40px;
    background-color: #f6f6f6;
    border-top: 1px solid #ebebeb;
    text-align: center;
    position: fixed;
    z-index: 99;
}
.page{height: 30px;line-height: 30px;margin: 0;padding: 0;text-align: center;background-color:#f8f8f8;}

</style>