<template>
  <a-spin :spinning="confirmLoading">
    <div class="box">
      <h3 class="title">项目详情</h3>
      <div class="content">
        <van-cell title="客户名称" :value="model.customerInfoSubName"></van-cell>
        <van-cell title="项目名称" :value="model.name"></van-cell>
        <van-cell title="项目编号" :value="model.serialNumber"></van-cell>
        <van-cell title="项目阶段" :value="stage"></van-cell>
        <van-cell title="省市区" >{{model.province+' '+model.city+' '+model.county}}</van-cell>
        <van-cell title="项目规模" :value="model.projectScale"></van-cell>
        <van-cell title="招投标代理机构" :value="model.biddingAgencyName"></van-cell>
        <van-cell title="招标平台" :value="model.cainfoName"></van-cell>
        <van-cell title="状态" :value="model.state">{{model.state==0?'跟踪':model.state==1?'失败':model.state==2?'延缓':model.state==3?'中止':'已立项'}}</van-cell>
        <van-cell title="投资金额(万元)" :value="model.investment"></van-cell>
        <van-cell title="业务类型" :value="model.projectClass"></van-cell>
        <van-cell title="责任部门" :value="model.applyDeptName"></van-cell>
        <van-cell title="项目负责人" :value="model.projectChargeUserName"></van-cell>
        <van-cell title="是否为投标项目" :value="model.isBid"></van-cell>
        <van-cell title="开标时间" :value="model.bidStartDate"></van-cell>
        <van-cell title="商务负责人" :value="model.businessManagerName"></van-cell>
        <van-cell title="投标起止日期" >{{model.startDate}} - {{model.endDate}}</van-cell>
        <van-cell title="来源" :value="model.source">{{model.source==1?'合同草案':model.source==2?'口头协议':model.source==3?'委托书':'投标'}}</van-cell>
        <van-cell title="预计合同额(元)" :value="model.estimatedContractAmount"></van-cell>
        <van-cell title="项目单价" :value="model.projectPrice"></van-cell>
        <van-cell title="客户联系人" :value="model.customerLinker"></van-cell>
        <van-cell title="责任公司所在省市区" >{{model.fzProvince}}  {{model.fzCity}} </van-cell>
        <van-cell title="行业代码" :value="model.projectClass"></van-cell>
        <van-cell title="登记负责人" :value="model.registerName"></van-cell>
        <van-cell title="登记时间" :value="model.registeDate"></van-cell>
        <van-cell title="项目内容" :value="model.content"></van-cell>
        <van-cell title="备注" :value="model.remark"></van-cell>
        <!-- <van-cell>
            <template #title>
              <span class="custom-title">文件路径</span>
            </template>
            <template #default>
              <a style="display:block;margin:5px 0;"
                :href="model.signminiourl" rel="noopener noreferrer">
                下载预览
              </a>
            </template>
        </van-cell> -->
      </div>
    </div>
  </a-spin>
</template>

<script>
import {
  getJYqrcodedetails,
  getDict
} from '@/api/api'
import { 
  Cell 
} from 'vant';

export default {
    //二维码发文管理详情页
    name:'JYqrcodedetails',
    components:{
      [Cell.name]:Cell,
    },
    data() {
        return {
          confirmLoading:false,
          model:{},
          DataDictionaryList:['project_phase',],
          jdDataList:[],       
          stage:''
        }
    },
    mounted() {
    },
    methods:{
      async getJYqrcodedetails(val){
        this.confirmLoading = true;
        try {
          const datas= await getJYqrcodedetails({id:val})
          if(datas.success){
            this.model=datas.result
            this.jdDataList=this.model.phase.split(',')
            this.getDict()
            
            this.confirmLoading = false;
          }
        } catch (error) {
          this.$message.error(error)
          this.confirmLoading = false;
        }
      },
        async getDict(val){
        this.confirmLoading = true;
        try {
          const datas= await getDict({code:'project_phase'})
          if(datas.success){
            console.log(datas,'datas');
            // let arr= this.model.phase.split(',')
            console.log(this.jdDataList,'123');
            let arr=[]
            datas.result.project_phase.forEach((e,i)=>{
             if(this.jdDataList.find(item=>item==e.value)){
               console.log(e.text,'eeeee');
               arr.push(e.text)
             }
            })
          arr= arr.join(',')
          this.stage=arr
            // this.confirmLoading = false;
          }
        } catch (error) {
          this.$message.error(error)
          // this.confirmLoading = false;
        }
      },
    },
    computed:{
        reQuery() {
          return this.$route.query
        },
        contract_id() {
          return this.reQuery.id || '1526079130383450113';
        }
    },
    watch:{
      'contract_id':{
          handler(val) {
            if(val) {
              this.getJYqrcodedetails(val);
            }
          },
          immediate:true
      }
    },
}
</script>

<style lang="less" scoped>
.title{text-align: center;padding: 5px 0;background-color:#002b45 ;color: #fff;}
.content {height: calc(100vh - 95px);overflow-y: auto;}
</style>
