<template>
    <a-spin :spinning="confirmLoading">
        <div class="box">
            <h3 class="title">
                <span>排查报告</span>
                <img :src="require('@/assets/logo.png')" alt="首辅" srcset="" 
                style="width:60px;height:30px;margin-right:10px;">
            </h3>
            
            <div class="content">
                <van-cell title="项目名称" :value="model.name" ></van-cell>
                <van-cell title="项目地址" :value="model.addres"></van-cell>
                <van-cell title="排查单位" :value="model.applyDept"></van-cell>
                <van-cell title="公司负责人">
                    <template #default v-if="model.branchManager">
                        <span>{{model.branchManager}}：</span>
                        <span>{{model.branchManagerPhone}}</span>
                    </template>
                    <template #default v-else>
                        <span>-----</span>
                    </template>
                </van-cell>
                <van-cell title="项目负责人">
                    <template #default>
                        <span>{{model.projectChargeUserName}}：</span>
                        <span>{{model.projectChargeUserPhone}}</span>
                    </template>
                </van-cell>
            </div>
        </div>
    </a-spin>
</template>

<script>
import {
    getPcInfo
} from '@/api/api'
import { 
    Cell
} from 'vant';
import {getAres,isNaNFlag} from '@/utils/util'

export default {
    name:'pcReportInfo',
    components:{
        [Cell.name]:Cell,
    },
    computed:{
        reQuery() {
            return this.$route.query
        },
        report_id() {
            return this.reQuery.id;
        }
    },
    watch:{
        'report_id':{
            handler(val) {
                console.log('==========项目id',val)
                if(val) {
                    this.initPcInfo(val);
                }
            },
            immediate:true
        }
    },
    data() {
        return {
            confirmLoading:false,
            model:{},
        }
    },
    methods:{
        initPcInfo(projectId) {
            getPcInfo({projectId}).then(res => {
                if(res.success) {
                    let province = !res.result.province?"":isNaNFlag(res.result.province)?getAres(res.result.province,'text'):res.result.province
                    let city = !res.result.city?"":isNaNFlag(res.result.city)?getAres(res.result.city,'text'):res.result.city
                    let county = !res.result.county?"":isNaNFlag(res.result.county)?getAres(res.result.county,'text'):res.result.county
                    let addres = province+city+county+res.result.township
                    this.model = {
                        ...res.result,
                        addres
                    };
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.title{
    text-align: center;
    padding: 5px 0;
    background-color:#002b45 ;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    >span{flex:1;text-align: center;transform: translateX(34px);}
}
/deep/.van-cell__value{flex:2;text-align: left;}
.content {height: calc(100vh - 95px);overflow-y: auto;}
</style>