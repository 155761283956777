<template>
  <a-spin :spinning="confirmLoading"> </a-spin>
</template>

<script>
import { TurningThePage } from "@/api/activitiApi";
import { getQueryString } from "@/utils/util";

export default {
  //签字人申请,中转页面
  name: "TurningThePage",
  components: {},
  data() {
    return {
      confirmLoading: false,
      model: {},
    };
  },
  mounted() {},
  methods: {
    async TurningThePage(val) {
      this.confirmLoading = true;
      try {
        const datas = await TurningThePage({ id: val });
        if (datas.success) {
          if (!datas.result || !datas.result.url) {
            this.$message.error("获取页面失效!");
            this.confirmLoading = false;
          } else {
            let routeUrl = datas.result.url;
            //  window.open(routeUrl,'pdf_signed_application')
            // 解决ios未跳转
            console.log(routeUrl);
            window.location.href = routeUrl;
            // if (r != null) return decodeURIComponent(r[2]);
            // this.model=datas.result
            // this.jdDataList=this.model.phase.split(',')
            // this.getDict()
            this.confirmLoading = false;
          }
        }
      } catch (error) {
        // this.$message.error(error);
        this.confirmLoading = false;
      }
    },
  },
  computed: {
    reQuery() {
      return this.$route.query;
    },
    contract_id() {
      return this.$route.query.id;
    },
    // business_key(){
    //   return this.$route.query.businessKey || "1528563317854220290";
    // },
    token() {
      return this.$route.query.token;
    },
  },
  watch: {
    contract_id: {
      handler(val) {
        if (val) {
          this.TurningThePage(val);
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  text-align: center;
  padding: 5px 0;
  background-color: #002b45;
  color: #fff;
}
.content {
  height: calc(100vh - 95px);
  overflow-y: auto;
}
.butBox {
  margin-top: 10px;
  padding-right: 15px;
  display: flex;
  justify-content: flex-end;
}
</style>
